.clever_oauth_page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.error_page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 700px;

    .error_message {
        font-size: 2rem;
        font-weight: 500;
        margin: 20px 0;
    }

    .error_description {
        font-size: 1rem;
        font-weight: 400;
        margin: 20px 0;
        text-align: center;

        > p {
            margin: 4px 0;
            font-size: inherit;
        }
    }

    .button {
        margin: 10px 0;
        padding: 12px 36px;

        background-color: #0085ff;
        font-weight: 400;
        font-size: 1rem;
        color: white;
        border-radius: 4px;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: #0099ff;
        }
    }
}

.loading_page_container {
    text-align: center;

    .loading_page_spinner {
        width: 80px;
        margin-bottom: 10px;
    }

    .loading_message {
        font-size: 1rem;
    }
}

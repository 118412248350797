/* DEPRECATED PAGE IS NO LONGER DISPLAYED - Eugene 8/15/2024 */

.subscription_required {
    position: absolute;
    top: -15px;
    right: -20px;
    display: flex;
    gap: 5px;
    align-items: center;
    color: #983705;
    background-color: #ffd46e;
    padding: 5px 10px 5px 10px;
    border-radius: 7px;
    font-family: "Inter", sans-serif;
    font-weight: bolder;
    font-size: 0.8rem;

    .subscription_tag_img {
        width: 30px;
        height: auto;
        margin-bottom: 5px;
    }
}

.main_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    max-width: 900px;
    margin: 0 auto 40px;
    padding: 0px 20px 40px;

    font-family: "Inter", sans-serif;

    > div:first-child {
        margin-bottom: 8px;
    }
}

.title {
    font-size: 1.75rem;
    font-weight: 600;
    margin: 20px 0 30px 0;
}

.description {
    font-size: 0.875rem;
    line-height: 1.5;

    p {
        font-weight: normal;
        font-size: 0.875rem;
        margin: 1rem 0;
    }
}

.tabs_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 35px;

    gap: 10px;
    width: 100%;
    font-weight: 600;
    font-size: 0.875rem;
    overflow: auto;

    border-bottom: 1.5px solid #d2d2d2;

    .tab {
        font-weight: 600;
        /* font-size: 0.875rem; */
        color: #424242;
        cursor: pointer;
        padding: 6px 10px;
        border-bottom: 3px solid transparent;
        flex-shrink: 0;
    }

    .active_tab {
        position: relative;
        font-weight: 600;
        color: #0085ff;
        border-bottom-color: #0085ff;
    }

    /*
    If we want the underline to be bolder, we can use the following code.
    Note, this is less robust, especially if the header goes over one line.
    .active_tab::before {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: -1.5px;
        border-bottom: 3px solid #0085ff;
        z-index: 1;
    }
    */
}

.question_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    margin: 10px 0;
    font-weight: 500;

    .question {
        font-size: 0.875rem;
    }

    .textarea {
        box-sizing: border-box;
        width: 100%;
        height: 120px;
        padding: 10px;

        font-size: 0.875rem;
        font-family: "Inter", sans-serif;
        line-height: 1.5;

        border: 1px solid #d2d2d2;
        border-radius: 5px;
        resize: none;
    }
}

a {
    color: #0085ff;
    text-decoration: underline;
}

.scholarships_container_v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    padding: 0 20px;
    height: 100%;

    animation: fade 0.2s forwards;

    .scholarships_description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        max-width: 700px;
        margin: 20px 0;

        .scholarships_header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            width: 100%;
            max-width: 700px;
            margin-bottom: 20px;

            .scholarships_title_container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 12px;
            }

            .scholarships_title {
                font-size: 1.75rem;
                font-weight: 600;
                margin: 0;
                max-width: 700px;
                width: 100%;
                text-align: flex-start;
            }

            .tag {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 4px;

                background-color: #bedbff;
                border-radius: 100px;
                padding: 3px 12px;

                .tag_text {
                    font-size: 0.875rem;
                    font-weight: 600;
                    color: #1c398e;
                }
            }

            .add_prompt_icon {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }

        .scholarships_text {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
        }
    }

    .add_prompt_button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        padding: 10px 22px;

        text-wrap: nowrap;
        font-weight: 500;
        font-size: 0.875rem;
        border-radius: 4px;
        background-color: #0085ff;
        color: white;
        transition: background-color 0.2s ease;
        cursor: pointer;

        &:hover {
            background-color: #0099ff;
        }

        .add_prompt_icon {
            width: 16px;
        }
    }

    .scholarships_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-grow: 1;

        max-width: 700px;
        width: 100%;
        margin: 0 auto;

        .scholarships_prompt_list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;

            width: 100%;
            padding: 0;
            margin: 0;
        }

        .scholarships_prompt_card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;
            gap: 8px;

            padding: 16px 24px;
            border: 1px solid #d2d2d2;
            border-radius: 4px;
            width: 100%;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border 0.2s ease, box-shadow 0.2s ease;

            word-wrap: break-word;

            &:hover {
                border: 1px solid #0085ff;
                box-shadow: 0 2px 4px rgba(0, 133, 255, 0.2); /* #0085ff */
                cursor: pointer;
            }

            &:active {
                box-shadow: inset 0 0 4px rgba(0, 133, 255, 0.5), 0 2px 4px rgba(0, 133, 255, 0.2); /* #0085ff */
            }

            .card_title {
                font-weight: 500;
                /* font-size: 0.875rem; */
            }

            .card_text {
                font-weight: 400;
                font-size: 0.875rem;
            }

            .action_menu_container {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
                position: absolute;
                right: 20px;
                top: 12px;

                .action_menu_dots {
                    width: 32px;
                    padding: 4px;
                    border-radius: 4px;
                    transition: background-color 0.2s ease;

                    &:hover {
                        cursor: pointer;
                        background-color: aliceblue;
                    }
                }

                .action_menu {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    position: absolute;
                    top: 32px;
                    z-index: 2;
                    background-color: white;

                    width: fit-content;
                    padding: 4px 0;

                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    border-radius: 0 0 4px 4px;
                    border: 1px solid aliceblue;
                    overflow: hidden;

                    .menu_item {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 12px;

                        padding: 10px 18px;
                        width: 100%;
                        font-weight: 400;
                        font-size: 0.875rem;
                        transition: background-color 0.2s ease;
                        cursor: pointer;

                        &:hover {
                            background-color: aliceblue;
                        }

                        .menu_icon {
                            width: 20px;
                        }

                        .menu_text {
                            font-size: 1rem;
                            font-weight: 500;
                            line-height: 1.25;
                        }

                        .delete_text {
                            color: #f44336;
                        }
                    }
                }
            }
        }

        .empty_scholarships_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            gap: 20px;

            width: 100%;
            height: 100%;
            margin-bottom: 100px;

            .empty_scholarships_text {
                font-size: 1rem;
                font-weight: 500;
                width: 100%;
                text-align: center;
            }

            .empty_scholarships_icon {
                width: 64px;
            }
        }
    }
}

.add_custom_prompt_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;

    padding-left: 225px;

    width: 100%;
    height: 100%;
    z-index: 0;

    animation: fade 0.2s forwards;

    .modal_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        padding: 20px 50px;
        width: 700px;

        border-radius: 8px;
        background-color: white;
        z-index: 69;
        box-shadow: 0 4px 8px rgb(0, 0, 0, 0.25);

        .dropdown_input_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            margin: 12px 0;
            width: 100%;
            height: 70px;
        }

        .dropdown_label {
            font-size: 0.875rem;
            margin-bottom: 4px;
            font-weight: 500;
        }
    }

    .modal_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        margin: 20px 0 10px;
        width: 100%;

        .custom_prompt_title_input {
            margin: 0;
            flex-grow: 1;

            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.25;
            padding: 8px 0;
            border: 0;

            &:focus {
                padding-left: 10px;
            }
        }

        .modal_title {
            flex-grow: 1;
            font-size: 1.5rem;
            font-weight: 600;
            margin: 0;
        }

        .close_button {
            background-color: transparent;
            border-radius: 4px;

            width: 40px;
            height: 40px;
            padding: 8px;

            img {
                width: 100%;
            }

            &:hover {
                cursor: pointer;
                background-color: aliceblue;
            }
        }
    }

    .modal_body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;

        .modal_input_section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            width: 100%;
            padding: 0;
            margin: 8px 0;

            /* TODO (Eugene): Temp need this here since TooltipTrigger doesn't accept custom classe */
            .modal_info_button {
                background: none;
                padding: 0;
            }

            .modal_input_label {
                font-size: 0.875rem;
                font-weight: 500;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 6px;
            }

            .help_icon {
                width: 16px;
                cursor: pointer;
            }

            .custom_prompt_title_input {
                display: block;
                padding: 12px;
                height: 2.5rem;
                margin: 8px 0;
                width: 100%;

                border: 0;
                outline: 1px solid #d2d2d2;
                border-radius: 4px;
                font-size: 0.875rem;
                font-family: inherit;
            }

            .modal_textarea {
                font-family: inherit;
                font-size: 0.875rem;
                line-height: 1.5;
                border-radius: 4px;
                border-color: #d2d2d2;

                width: 100%;
                margin: 8px 0;
                height: 10rem;
                padding: 10px 12px;
                resize: vertical;
            }

            .max_word_count_input {
                display: block;
                padding: 12px;
                height: 2.5rem;
                margin: 8px 0;

                border: 0;
                outline: 1px solid #d2d2d2;
                border-radius: 4px;
                font-size: 0.875rem;
                font-family: inherit;
            }
        }
    }

    .modal_footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;

        margin: 20px 0;
        width: 100%;

        .cancel_button,
        .add_prompt_button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 140px;

            padding: 10px 12px;

            font-weight: 500;
            font-size: 0.875rem;
            text-wrap: nowrap;
            border-radius: 4px;

            img {
                width: 18px;
            }
        }

        .add_prompt_button {
            background-color: #0085ff;
            color: white;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: #0099ff;
                cursor: pointer;
            }
        }

        .cancel_button {
            color: #0085ff;
            background-color: white;
            border: 1px solid #0085ff80;
            transition: background-color 0.2s ease, border-color 0.2s ease;

            &:hover {
                cursor: pointer;
                background-color: #f2f2f2;
                border-color: #0085ff;
            }
        }

        .disabled {
            background-color: #d2d2d2;
            color: white;
            cursor: not-allowed;

            &:hover {
                background-color: #d2d2d2;
                cursor: not-allowed;
            }
        }
    }

    .gray_background {
        display: block;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.delete_school_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;

    padding-left: 225px;

    width: 100%;
    height: 100%;
    z-index: 0;

    animation: fade 0.2s forwards;

    .modal_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        padding: 20px 50px;
        width: 700px;

        border-radius: 8px;
        background-color: white;
        z-index: 69;
        box-shadow: 0 4px 8px rgb(0, 0, 0, 0.25);

        .dropdown_input_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            margin: 12px 0;
            width: 100%;
            height: 70px;
        }

        .dropdown_label {
            font-size: 0.875rem;
            margin-bottom: 4px;
            font-weight: 500;
        }
    }

    .modal_header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        margin: 20px 0;
        width: 100%;

        .modal_title {
            margin: 0;
            flex-grow: 1;

            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.25;
        }
    }

    .modal_footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;

        margin: 30px 0 20px;
        width: 100%;
    }

    .modal_add_school_button,
    .modal_edit_school_button,
    .modal_delete_school_button,
    .cancel_button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 140px;

        padding: 10px 12px;

        font-weight: 500;
        font-size: 0.875rem;
        text-wrap: nowrap;
        border-radius: 4px;

        img {
            width: 18px;
        }
    }

    .cancel_button {
        padding: 9px 12px; /* Offset y-offset by 1px since we gain 1px from border */
    }

    .modal_add_school_button,
    .modal_edit_school_button {
        color: white;
        background: #0085ff;
        transition: filter 0.2s ease;

        &:hover {
            cursor: pointer;
            filter: brightness(1.1);
        }
    }

    .modal_delete_school_button {
        color: white;
        background: #f44336;
        transition: filter 0.2s ease;

        &:hover {
            cursor: pointer;
            filter: brightness(1.1);
        }
    }

    .cancel_button {
        color: #0085ff;
        background-color: white;
        border: 1px solid #0085ff80;
        transition: background-color 0.2s ease, border-color 0.2s ease;

        &:hover {
            cursor: pointer;
            background-color: #f2f2f2;
            border-color: #0085ff;
        }
    }

    .disabled_add_school_button {
        background: #0085ff;
        cursor: not-allowed;
        filter: brightness(0.8);

        &:hover {
            cursor: not-allowed;
            filter: brightness(0.8);
        }
    }

    .close_button {
        background-color: transparent;
        border-radius: 4px;

        width: 32px;
        height: 32px;
        padding: 4px;

        img {
            width: 100%;
        }

        &:hover {
            cursor: pointer;
            background-color: aliceblue;
        }
    }

    .modal_warning_text {
        font-size: 1rem;
        font-weight: normal;
        margin: 8px 0;

        .modal_warning_text_emphasis {
            font-weight: 600;
        }
    }

    .gray_background {
        display: block;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.modal_info_tooltip_container {
    max-width: 280px;
    background: #0085ff;
    padding: 16px 24px;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.sidebar_container_v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background-color: aliceblue;
    color: black;
    font-size: 0.875rem;
    font-weight: normal;

    /* padding: 20px 16px 20px 20px; */
    padding: 20px 0;
    box-sizing: border-box; /* This is to make sure that the padding is included in the height */
    top: 0;
    left: 0;
    width: 225px; /* Do not change this without searching for all instances of this px value in the code, see comments */
    position: fixed;
    height: 100vh;
    /* box-shadow: 2px 0px 4px rgba(0, 133, 255, 0.1); */
    border-right: 1px solid #0085ff40;
    z-index: 500; /* Sidebar needs to be above everything except for entire-page modals */

    .sidebar_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        gap: 8px;

        width: 100%;
        margin: 10px 0 0;
        padding: 0 16px 0 20px;

        .sidebar_title_text {
            font-size: 1.25rem;
            margin: 0 0 -3px 0;
            font-family: "Montserrat", "Inter", sans-serif;
            font-weight: 600;

            background: linear-gradient(90deg, #ff7971 0%, #0085ff 75%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
        }

        .sidebar_title_subtext {
            font-size: 0.75rem;
            letter-spacing: -0.0075rem;
            color: #838b9f;
        }

        .sidebar_title_logo_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 36px;
            height: 36px;
            background-color: #0085ff;
            border-radius: 6px;

            .sidebar_title_logo {
                width: 22px;
                margin: 0;
                /* background-color: #0085ff; */
                /* margin-left: 10px; Match padding for action buttons */
            }
        }
    }

    .scrollable_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-grow: 1;

        width: 100%;
        margin: 0;
        padding: 0 16px 0 20px;
        overflow: auto;

        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.25) transparent;
    }

    /* Webkit-based browsers */
    .scrollable_section::-webkit-scrollbar {
        width: 4px; /* Set width */
    }

    .scrollable_section::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.25); /* Thumb color */
        border-radius: 10px; /* Rounded corners */
    }

    .scrollable_section::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.5); /* Darken thumb on hover */
    }

    .scrollable_section::-webkit-scrollbar-track {
        background: transparent; /* Hide scrollbar track */
    }

    .sidebar_navigation_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        margin: 0;
        /* padding: 10px 30px; */
        flex-shrink: 0;
        overflow: auto;
    }

    .flex_grow_div {
        flex-grow: 1;
    }

    .sidebar_bottom_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        width: 100%;
        margin-top: auto;
        flex-grow: 0;
        /* padding: 10px 30px 0; */

        .upgrade_button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;

            width: 100%;
            padding: 10px 0;
            margin: 10px 0;

            color: white;
            background-color: #0085ff;
            border-radius: 5px;
            font-weight: 500;

            cursor: pointer;
            transition: background-color 0.2s ease;

            .action_button_icon {
                width: 16px;
                height: 16px;
                margin: 0;
            }

            &:hover {
                background-color: #0099ff;
            }
        }

        .sidebar_profile_section {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;

            .profile_card {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                margin: 10px 0;

                .profile_picture_v2 {
                    max-width: 40px;
                    max-height: 40px;
                    border-radius: 50%;
                }

                .profile_info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    flex-grow: 1;
                    margin: 0;

                    .profile_name {
                        font-weight: 600;
                    }

                    .profile_credits_remaining {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 4px;

                        .credit_icon {
                            max-width: 16px;
                            max-height: 16px;
                            margin: 3px;
                        }
                    }
                }
            }
        }
    }

    .action_button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        padding: 8px 12px;
        margin: 2px 0;
        width: 100%;

        cursor: pointer;

        .tag {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 4px;

            background-color: #bedbff;
            border-radius: 100px;
            padding: 2px 8px;

            .tag_text {
                font-size: 0.75rem;
                font-weight: 500;
                color: #1c398e;
            }
        }

        .action_button_icon {
            width: 18px;
            height: 18px;
            margin: 0;
            flex-shrink: 0;
        }

        &:hover {
            /* background-color: rgba(0, 133, 255, 0.1); */
            background-color: white;
            color: #0085ff;
            border: 1px solid #d9e8ff;
        }
    }

    .sidebar_active_hoverable_entity,
    .sidebar_hoverable_entity {
        flex-grow: 0;
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid transparent;
        font-weight: 500;
        color: #212121;

        transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
    }

    .sidebar_active_hoverable_entity {
        background-color: white;
        color: #0085ff;
        font-weight: 500;
        border: 1px solid #d9e8ff;
    }

    .sidebar_divider {
        width: calc(100% - 36px);
        border-top: 1px solid #e3e6f0;
        margin: 15px 0;
    }

    .section_title {
        font-size: 0.75rem;
        font-weight: 600;
        color: #9ba3ba;
    }

    /* TEMP JUST FOR DEMO */
    .dropdown_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;

        width: 100%;
        margin: 10px 0;

        .dropdown_section_title {
            font-size: 0.875rem;
            font-weight: 600;
            color: #212121;
        }
    }
}

@media print {
    .sidebar_container_v2 {
        display: none;
    }
}

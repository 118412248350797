/* DEPRECATED PAGE IS NO LONGER DISPLAYED - Eugene 8/15/2024 */

.main_section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;

    .page_title_container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .page_title {
        font-family: "Gothic A1";
        font-weight: bold;
        font-size: 2rem;
        width: 600px;
        text-align: center;
        margin-bottom: 50px;
    }

    .model_type_container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .model_type_title {
            font-family: "Gothic A1";
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: 20px;
        }

        .model_type_description {
            font-family: "Gothic A1";
            font-weight: normal;
            min-width: 500px;
            max-width: 800px;
        }
    }
}

.main_inner_section {
    max-width: min(80%, 1000px);
    min-width: 600px;
}

.widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    font-family: "Gothic A1";
    font-weight: bolder;
    font-size: 0.8rem;
    cursor: pointer;

    .widget_image {
        width: auto;
        height: 100px;
        margin-bottom: 25px;
    }

    .university_text {
        margin-left: 25px;
        margin-right: 25px;
        text-align: center;
        font-size: 1.1rem;
    }

    .subscription_required {
        position: absolute;
        top: -15px;
        right: -20px;
        display: flex;
        gap: 5px;
        align-items: center;
        color: #983705;
        background-color: #ffd46e;
        padding: 5px 10px 5px 10px;
        border-radius: 7px;

        .subscription_tag_img {
            width: 30px;
            height: auto;
            margin-bottom: 5px;
        }
    }
}

.school_type_section {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .school_type_title {
        font-family: "Gothic A1";
        font-weight: bolder;
        font-size: 1.5rem;
    }
}

.widgets_container {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 50px;
}

.bold {
    font-family: "Gothic A1";
    font-weight: bolder;
}

.response_feedback {
    width: clamp(400px, 100%, 825px);
    /* width: 225px; */
    padding: 55px 75px 55px 75px; /* Top, right, bottom, left TRouBLe */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.response_feedback_title {
    font-size: 1.3rem;
    margin-bottom: 10px;
}

.response_feedback_option_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0;
}

.response_feedback_option {
    border-radius: 4px;
    width: 50px;
    height: 30px;
    overflow: hidden;

    text-align: center;
}

.response_feedback_unselected_option {
    background-color: default;
    color: black;
}

.response_feedback_unselected_option:hover {
    background-color: rgb(0, 0, 0, 0.1);
}

.response_feedback_selected_option {
    background-color: black;
    color: white;
}

.response_feedback_input {
    min-width: 700px; /* Full width to maximize space */
    height: 300px; /* Enough height to display a good portion of text without scrolling */
    padding: 30px; /* Padding for text inside the textarea */

    font-family: "Inter", sans-serif; /* Readable font */
    font-weight: normal; /* Normal font weight */
    font-size: 1rem; /* Comfortable font size */
    line-height: 1.5; /* Line height for better readability */

    border: 1px solid #ccc; /* Subtle border */
    border-radius: 10px; /* Slightly rounded corners */

    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1); /* Soft box shadow for depth */
    background-color: #fff; /* White background */

    resize: vertical; /* Allow only vertical resizing */
    overflow: auto; /* Ensure scrollbar for overflow text */
    margin: 10px 0 20px;
}

.response_feedback_submit {
    background-color: black;
    color: white;
    padding: 8px 20px;
    border-radius: 4px;

    margin: 10px 0;
}

.response_feedback_submit:hover {
    background-color: #333333;
}

.response_feedback_thanks {
    font-size: 1rem;
}

.response_feedback_error {
    font-size: 1rem;
    border: 1px solid red;
    background-color: pink;
    padding: 15px 20px;
    border-radius: 4px;
    color: rgb(78, 78, 78);
}

.lw_feedback_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 20px;
    width: 100%;
    font-size: 0.875rem;

    .lw_feedback_title {
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .lw_feedback_button_container {
        display: flex;
        flex-direction: row;
        gap: 10px;

        margin: 8px 0;

        .lw_feedback_button {
            border-radius: 4px;
            padding: 8px 16px;
            background-color: #f0f8ff;
            color: #0085ff;
            font-size: 0.875rem;
            font-weight: 400;
            cursor: pointer;
            transition: background-color 0.2s ease-out;

            &:hover {
                background-color: #e0f0ff;
            }
        }

        .lw_feedback_button_selected {
            background-color: #0085ff;
            color: white;

            &:hover {
                background-color: #0077e6;
            }
        }
    }

    .lw_feedback_textarea {
        font-family: inherit;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 4px;

        width: 100%;
        min-height: 5rem;
        margin: 10px 0;
        padding: 8px;
        resize: vertical;
    }

    .lw_feedback_submit {
        margin: 8px 0;
        padding: 10px 20px;

        background-color: #0085ff;
        color: white;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s ease-out;

        &:hover {
            background-color: #0077e6;
        }
    }

    .lw_feedback_thanks {
        font-size: 0.875rem;
        margin: 10px 0 0;
    }

    .lw_feedback_error {
        padding: 8px 12px;
        max-width: 100%;

        font-size: 0.875rem;
        color: white;
        background-color: #e74c3c;
        border-radius: 4px;
    }
}

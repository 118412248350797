/* .dropdown_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid blue;
} */

.dropdown_container,
.disabled_dropdown_container {
    position: relative;

    width: 100%;
    height: 100%;
    max-height: 44px;

    border: 1px solid #0085ff40;
    border-radius: 4px;

    font-weight: normal;
    font-size: 0.875rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .dropdown_preview {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 4px 16px;
        width: 100%;
        height: 100%;
        min-height: 38px;
        border-radius: 4px;
        background-color: white;

        transition: background-color 0.2s ease-out;

        &:hover {
            background-color: #f0f8ff;
            cursor: pointer;
        }

        .rotated_dropdown_arrow {
            transform: rotate(180deg);
        }

        .dropdown_preview_prefix {
            font-weight: 500;
        }

        .dropdown_preview_content {
            overflow: hidden;
        }

        .dropdown_preview_icons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 4px;

            .dropdown_clear_icon {
                width: 24px;
                height: 24px;
                padding: 4px;
                border-radius: 12px;

                &:hover {
                    cursor: pointer;
                    background-color: #e0f0ff;
                }
            }
        }
    }

    .disabled_dropdown_preview {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 4px 16px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 4px;

        color: gray;
        background-color: #f2f2f2;
    }

    .dropdown_arrow {
        width: 18px;
        height: 18px;
        transition: transform 0.2s ease-out;
    }

    .dropdown_content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2px;
        z-index: 1;

        top: 40px;
        left: 0;
        width: 100%;
        max-height: 300px;
        padding: 4px 0px;
        overflow: auto;

        border: 1px solid #0085ff40;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 4px 4px;

        background-color: white;

        .dropdown_search_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-grow: 1;

            /* width: calc(100% - 16px); */
            padding: 6px 6px 6px 12px;
            /* border-bottom: 1px solid #0085ff40; */
            border-radius: 4px;
            border: 1px solid #0085ff40;
            margin: 4px 8px;

            .dropdown_search_clear_icon {
                width: 24px;
                height: 24px;
                padding: 4px;
                border-radius: 12px;

                &:hover {
                    cursor: pointer;
                    background-color: #e0f0ff;
                }
            }

            .dropdown_search_input {
                flex-grow: 1;
                border: none;
                outline: none;
                font-size: 0.875rem;
                min-height: 24px;
            }
        }

        .dropdown_item_list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 2px;

            width: 100%;
        }
    }

    .dropdown_placeholder {
        color: #808080;
        width: 100%;
        padding: 8px 0;
    }

    .dropdown_no_results_placeholder {
        color: #808080;
        width: 100%;
        padding: 8px;
    }

    .dropdown_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        gap: 10px;

        width: 100%;
        /* height: 32px; */
        padding: 10px 12px;

        transition: background-color 0.2s ease-out;

        &:hover {
            background-color: #f0f8ff;
            cursor: pointer;
        }

        .dropdown_item_name {
            flex-grow: 1;
            overflow: hidden;
        }

        .dropdown_item_tag {
            font-size: 0.75rem;
            color: #424242;
            background-color: #fae5b9;

            padding: 2px 4px;
            border-radius: 4px;
        }
    }
}

.disabled_dropdown_container {
    box-shadow: none;
}

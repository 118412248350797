.pricing_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 100px;
}

.pricing_title {
    font-weight: 500;
    font-size: 48px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .title_subtext {
        font-size: 1rem;
        font-weight: normal;
        max-width: 475px;
        margin-top: 10px;
    }
}

.pricing_table {
    border: 1px solid black;
}

.pricing_page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    font-family: "Helvetica Neue", sans-serif;
}

.pricing_option_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 0 100px;
    gap: 20px;
}

.pricing_option_subcontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

@media (max-width: 985px) {
    .pricing_option_container {
        flex-direction: column;
        align-items: center;
    }

    .pricing_option_subcontainer {
        flex-direction: column;
        align-items: center;
    }
}

.pricing_option_card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;

    padding: 30px;
    margin: 30px 10px;
    width: 310px;
    min-height: 500px;

    border-radius: 10px;
    box-shadow: 0 9px 16px -3px rgb(0, 0, 0, 0.25), 0 0px 10px -3px rgb(0, 131, 255, 0.2);
}

.pricing_option_card_extra_dimensions {
    margin: 0px 10px;
    width: 350px;
    border: 3px solid #0085ff;
}

.pricing_option_best_for_banner {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 20px 10px 20px;

    border-radius: 0 0 10px 0;
}

.pricing_option_best_for_text {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    position: relative;
    font-style: italic;
}

.pricing_option_top_section {
    display: flex;
    flex-direction: column;
    height: 160px;
    margin-top: 30px;
}

.pricing_option_top_section_extra_height {
    height: 190px;
}

.pricing_option_title_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .pricing_option_title {
        font-family: "Helvetica Neue";
        font-size: 2rem;
        font-weight: 500;
        margin: 0;
    }

    .pricing_option_badge {
        background-color: #fae5b9;
        width: fit-content;
        padding: 0.5rem 1.5rem;
        margin: 4px 0;
        border-radius: 50px;

        font-size: 1rem;
        font-weight: 500;
    }
}

.pricing_option_price_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    margin-bottom: 10px;

    .pricing_option_dollar_sign {
        font-size: 2rem;
        font-weight: 500;
        margin-right: 4px;
    }

    .pricing_option_price {
        font-size: 2rem;
        font-weight: 500;
    }

    .pricing_option_subprice {
        font-size: 1rem;
        font-weight: 500;
    }

    .pricing_option_plan_duration {
        font-size: 0.875rem;
        font-weight: 400;
        color: #888888;
    }
}

.pricing_option_features_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 13px;
}

.pricing_option_feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    gap: 10px;

    .pricing_option_feature_text {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: normal;
    }

    img {
        max-height: 20px;
    }
}

.pricing_option_button_container {
    margin-top: 20px;
    width: 100%;

    .pricing_option_current_plan {
        font-family: "Helvetica Neue", sans-serif;

        background-color: white;
        color: black;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        width: 100%;
        padding: 0.8rem;
        border-radius: 6px;
        border: 1px solid #0085ff;
        transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out;
        box-shadow: 0 4px 8px -2px rgb(0, 0, 0, 0.2);
    }

    .pricing_option_current_plan:hover {
        background-color: #0085ff11;
    }

    .pricing_option_current_plan:active {
        background-color: #0085ff33;
        box-shadow: 0 2px 8px -2px rgb(0, 0, 0, 0.35);
    }

    .pricing_option_button {
        font-family: "Helvetica Neue", sans-serif;

        color: white;
        font-weight: 500;
        font-size: 1rem;
        width: 100%;
        padding: 0.8rem;
        border-radius: 6px;
        transition: filter 0.2s ease-out;
    }

    .pricing_option_button:hover {
        filter: brightness(1.2);
    }

    .pricing_option_button:active {
        filter: brightness(1);
    }
}

.text_bold {
    font-weight: bold;
}

.horizontal_divider {
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    margin: 16px 0;
}

.empty_page {
    display: block;
    margin: 40px 0 0 0;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;

    .mail_link {
        color: inherit;
        text-decoration: none;
        font-weight: 500;
        transition: text-decoration 0.2s ease, color 0.2s ease;

        &:hover {
            text-decoration: underline;
            color: #0085ff;
        }
    }
}

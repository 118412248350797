.consultant_settings_page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    width: clamp(300px, 100%, 700px);
    margin: auto;
    padding: 0 20px 40px;

    .settings_header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        margin: 20px 0;
        max-width: 700px;

        .title {
            font-size: 1.75rem;
            font-weight: 600;
            margin: 0;
        }
    }

    .settings_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        width: 100%;
        max-width: 700px;
    }

    .banner {
        display: block;
        margin: 40px 0 0 0;
        font-size: 0.875rem;
        font-weight: 400;
        color: #333;
        text-align: center;

        .banner_mail_link {
            color: inherit;
            text-decoration: none;
            font-weight: 500;
            transition: text-decoration 0.2s ease, color 0.2s ease;

            &:hover {
                text-decoration: underline;
                color: #0085ff;
            }
        }
    }
}

.card {
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    padding: 20px;

    border-radius: 8px;
    border: 1px solid #e0e0e0;
    transition: border 0.2s ease;

    overflow-x: auto;
    overflow-y: visible;

    &:hover {
        border: 1px solid #0085ff;
    }

    .card_title {
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .card_body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        margin: 0;
        padding: 4px;
        width: 100%;
        font-size: 0.875rem;
        font-weight: 400;
        /* overflow-x: auto; */
        overflow-y: visible;

        .list_title {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
        }
    }

    .card_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
    }

    .card_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
        margin: 10px 0;
    }

    .table_container {
        overflow: auto;

        .table_page_button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 24px;
            height: 24px;

            font-weight: 400;
            font-size: 1rem;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            background-color: white;
            transition: background-color 0.2s ease;

            &:hover {
                border-color: #0085ff;
            }

            .table_page_button_icon {
                width: 16px;
                height: 16px;
            }
        }
    }

    .rotate_90_deg {
        transform: rotate(90deg);
    }

    .rotate_270_deg {
        transform: rotate(270deg);
    }

    .bulletless_list {
        list-style-type: none;
        padding: 10px;
    }

    ul {
        margin: 0;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        overflow: auto;
    }

    td,
    th {
        border: 1px solid #ddd;
        padding: 8px;
        min-width: 100px;
    }

    th {
        background-color: #f2f2f2;
        text-align: left;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    tr:hover {
        background-color: #ddd;
    }

    .two_column_list {
        column-count: 2;
    }

    .three_column_list {
        column-count: 3;
        column-gap: 20px;
    }
}

.add_students_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .add_students_form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
        width: 100%;

        .side_by_side {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 30px;
        }

        .form_section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: clamp(200px, 100%, 400px);

            > label {
                font-size: 0.875rem;
                font-weight: 500;
                margin: 6px 0;
            }

            > input {
                padding: 10px 12px;
                width: 100%;
                border-radius: 4px;
                border: none;
                outline: 1px solid #e0e0e0;

                font-size: 0.875rem;
                font-weight: 400;
                transition: outline 0.2s ease;

                &:focus {
                    outline: 1px solid #0085ff;
                }
            }

            .mimic_input {
                padding: 10px 12px;
                width: 100%;
                border-radius: 4px;
                border: none;
                outline: 1px solid #e0e0e0;

                font-size: 0.875rem;
                font-weight: 400;
                transition: outline 0.2s ease;

                &:focus {
                    outline: 1px solid #0085ff;
                }
            }
        }

        .add_students_button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            padding: 10px 32px;
            margin: 32px 0px 16px 0px;

            border: none;
            border-radius: 4px;
            background-color: #0085ff;
            color: white;
            font-size: 0.875rem;
            font-weight: 500;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: #0099ff;
            }
        }

        .disabled_add_student_button {
            background: #0085ff;
            cursor: not-allowed;
            filter: brightness(0.8);

            &:hover {
                cursor: not-allowed;
                background-color: #0085ff;
            }
        }
    }
}

.confirmation_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;

    padding-left: 225px;

    width: 100%;
    height: 100%;
    z-index: 100;

    .modal_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        padding: 20px 50px;
        width: 700px;

        border-radius: 8px;
        background-color: white;
        z-index: 101;
        box-shadow: 0 4px 8px rgb(0, 0, 0, 0.25);
    }

    .modal_header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        margin: 20px 0;
        width: 100%;

        .modal_title {
            margin: 0;
            flex-grow: 1;

            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.25;
        }
    }

    .close_button {
        background-color: transparent;
        border-radius: 4px;

        width: 32px;
        height: 32px;
        padding: 4px;

        img {
            width: 100%;
        }

        &:hover {
            cursor: pointer;
            background-color: aliceblue;
        }
    }

    .modal_warning_text {
        font-size: 1rem;
        font-weight: normal;
        margin: 8px 0;

        .modal_warning_text_emphasis {
            font-weight: 600;
        }
    }

    .modal_footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;

        margin: 30px 0 20px;
        width: 100%;
    }

    .modal_add_student_button,
    .cancel_button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 140px;

        padding: 10px 12px;

        font-weight: 500;
        font-size: 0.875rem;
        text-wrap: nowrap;
        border-radius: 4px;

        img {
            width: 18px;
        }
    }

    .modal_add_student_button {
        color: white;
        background: #0085ff;
        transition: filter 0.2s ease;

        &:hover {
            cursor: pointer;
            filter: brightness(1.1);
        }
    }

    .cancel_button {
        padding: 9px 12px; /* Offset y-offset by 1px since we gain 1px from border */

        color: #0085ff;
        background-color: white;
        border: 1px solid #0085ff80;
        transition: background-color 0.2s ease, border-color 0.2s ease;

        &:hover {
            cursor: pointer;
            background-color: #f2f2f2;
            border-color: #0085ff;
        }
    }

    .gray_background {
        display: block;
        position: fixed;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        z-index: 69;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

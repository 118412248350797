/*
 * Toast styles for Athena! 🚀
 */

.toast_container {
    font-weight: 400;
}

:root {
    --toastify-font-family: "Inter", sans-serif;
}

.topbar {
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;

    .athena_logo {
        width: 140px;

        &:hover {
            cursor: pointer;
        }
    }

    .menu {
        display: flex;
        gap: 50px;

        .step {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            .step_number {
                height: 40px;
                width: 40px;
                min-height: 40px;
                min-width: 40px;
                padding: 10px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .step_number_unreached {
                color: rgb(105, 105, 105);
                border: solid 1px rgb(105, 105, 105);
            }

            .step_number_finished_or_current {
                color: #ffffff;
                background-color: #0085ff;
                border: solid 1px #0085ff;
            }

            .step_text_unreached {
                color: rgb(105, 105, 105);
            }

            .step_text_finished_or_current {
                color: #0085ff;
            }
        }
    }
}

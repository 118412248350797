.form_page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    width: 100%;
    padding: 0 20px 40px;

    .page_description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        margin: 20px 0;

        .title {
            font-size: 1.75rem;
            font-weight: 600;
            margin: 0;
        }
    }

    .page_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        width: 100%;
        max-width: 700px;
    }

    .banner {
        display: block;
        margin: 40px 0 0 0;
        font-size: 0.875rem;
        font-weight: 400;
        color: #333;
        text-align: center;

        .banner_mail_link {
            color: inherit;
            text-decoration: none;
            font-weight: 500;
            transition: text-decoration 0.2s ease, color 0.2s ease;

            &:hover {
                text-decoration: underline;
                color: #0085ff;
            }
        }
    }
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
        width: 100%;

        .side_by_side {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 30px;
            /* width: clamp(200px, 100%, 400px); */
            width: 100%;
        }

        .form_section,
        .form_long_section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: clamp(200px, 100%, 450px);

            > label {
                font-size: 0.875rem;
                font-weight: 500;
                margin: 6px 0;
            }

            > input {
                padding: 10px 12px;
                width: 100%;
                border-radius: 4px;
                border: none;
                outline: 1px solid #e0e0e0;

                font-size: 0.875rem;
                font-weight: 400;
                transition: outline 0.2s ease;

                &:focus {
                    outline: 1px solid #0085ff;
                }
            }

            > textarea {
                padding: 10px 12px;
                width: 100%;
                border-radius: 4px;
                border: none;
                outline: 1px solid #e0e0e0;

                font-size: 0.875rem;
                font-weight: 400;
                font-family: "Inter", sans-serif;
                transition: outline 0.2s ease;

                &:focus {
                    outline: 1px solid #0085ff;
                }
            }

            .mimic_input {
                padding: 10px 12px;
                width: 100%;
                border-radius: 4px;
                border: none;
                outline: 1px solid #e0e0e0;

                font-size: 0.875rem;
                font-weight: 400;
                transition: outline 0.2s ease;

                &:focus {
                    outline: 1px solid #0085ff;
                }
            }
        }

        .form_long_section {
            width: clamp(200px, 100%, 830px);
        }

        .form_button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            padding: 10px 32px;
            margin: 24px 0px 16px 0px;

            border: none;
            border-radius: 4px;
            background-color: #0085ff;
            color: white;
            font-size: 0.875rem;
            font-weight: 500;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: #0099ff;
            }
        }

        .disabled_form_button {
            background: #0085ff;
            cursor: not-allowed;
            filter: brightness(0.8);

            &:hover {
                cursor: not-allowed;
                background-color: #0085ff;
            }
        }

        .form_error {
            font-size: 0.875rem;
            font-weight: 400;
            color: #ff0000;
            margin: 16px 0 0 0;
        }
    }
}

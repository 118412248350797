.page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 0 20px;

    .page_title {
        font-size: 1.75rem;
        font-weight: 600;
        margin: 20px 0;
        width: 100%;
        flex-grow: 1;
    }
}

.card {
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    padding: 20px;

    border-radius: 8px;
    border: 1px solid #e0e0e0;
    transition: border 0.2s ease;
    overflow: hidden;

    &:hover {
        border: 1px solid #0085ff;
    }

    .card_title {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0 0 12px;
    }

    .card_body {
        font-size: 0.875rem;
        font-weight: 400;

        .list_title {
            font-size: 1rem;
            font-weight: 500;
        }
    }

    .card_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 16px 0;

        gap: 12px;
    }

    .card_description {
        font-size: 0.875rem;
        font-weight: 400;
        margin: 0 0 4px;

        .link {
            font-weight: 600;
            text-decoration: none;
            color: black;
            transition: color 0.2s ease;

            &:hover {
                color: #0085ff;
            }
        }
    }

    .card_loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;

        .loading_spinner {
            width: 40px;
            height: 40px;
            margin: 20px 0;
        }
    }

    .card_row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;

        > p {
            margin: 8px 0;
        }

        .row_title {
            font-weight: 500;
            font-size: 0.875rem;
        }

        .row_content {
            font-weight: 400;
            font-size: 0.875rem;
        }

        .row_content_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            margin: auto 0;
        }

        .gradient_text {
            font-weight: 600;
            background: linear-gradient(90deg, #ff7971 0%, #0085ff 75%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
        }

        .progress_bar_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 12px;

            margin: 8px 0;

            .progress_bar_row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                flex-wrap: wrap;

                padding: 0 4px;

                .referral_success_text {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 4px;

                    font-size: 0.875rem;
                    font-weight: 500;
                }
            }

            .progress_bar {
                width: 160px;
                flex-shrink: 0;
            }
        }
    }

    .card_button {
        padding: 8px 16px;
        font-size: 0.875rem;
        font-weight: 500;
        border-radius: 5px;
        background-color: #0085ff;
        color: white;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0099ff;
        }

        &:active {
            background-color: #0085ff;
        }
    }
}

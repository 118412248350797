.page_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading_spinner {
    width: 64px;
    height: 64px;
}

.redirect_text {
    font-size: 1rem;
    text-align: center;
}

.page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    width: clamp(300px, 100%, 900px);
    margin: 0 auto;
    padding: 0 20px 40px;

    .page_header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        margin: 20px 0;
        max-width: 900px;

        .title {
            font-size: 1.75rem;
            font-weight: 600;
            margin: 0;
        }

        .dashboard_return {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;

            width: 100%;
            margin: -20px 0 10px 0;
            font-size: 0.875rem;
            font-weight: 400;
            color: #424242;
            cursor: pointer;
            transition: color 0.2s ease;

            &:hover {
                color: #0085ff;
            }
        }

        .dashboard_return_icon {
            width: 20px;
            height: 20px;
        }
    }

    .page_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        max-width: 900px;
        margin: 0 auto;
    }

    .tools_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        flex-wrap: wrap;
        gap: 40px;

        width: 100%;
        max-width: 900px;
        padding: 20px 0;
    }

    .banner {
        display: block;
        margin: 40px 0 0 0;
        font-size: 0.875rem;
        font-weight: 400;
        color: #333;
        text-align: center;

        .banner_mail_link {
            color: inherit;
            text-decoration: none;
            font-weight: 500;
            transition: text-decoration 0.2s ease, color 0.2s ease;

            &:hover {
                text-decoration: underline;
                color: #0085ff;
            }
        }
    }

    .tabs_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;

        gap: 10px;
        width: 100%;
        font-weight: 500;
        font-size: 0.875rem;

        border-bottom: 1px solid #e0e0e0;

        overflow: hidden;

        .tab {
            color: #424242;
            cursor: pointer;
            padding: 8px 10px;
            border-bottom: 3px solid transparent;
            text-wrap: nowrap;
        }

        .active_tab {
            position: relative;
            color: #0085ff;
            border-bottom-color: #0085ff;
        }
    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 10px 20px;
    width: 240px;
    height: 280px;

    border: 1px solid #e0e0e040;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 133, 255, 0.1);
    overflow: hidden;
    transition: border 0.2s ease, box-shadow 0.2s ease;

    &:hover {
        border: 1px solid #0085ff;
        cursor: pointer;
        box-shadow: 0 0 5px 0 rgba(0, 133, 255, 0.2);
    }

    .card_icon {
        width: 160px;
        height: 160px;
        flex-shrink: 0;
    }

    .card_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        .card_title {
            font-size: 1.125rem;
            font-weight: 500;
            margin: 0;
            text-align: center;
        }

        .card_description {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
            text-align: center;
        }
    }
}

.green_check_mark_container {
    width: 100px;
    height: 100px;
    overflow: hidden;
}

@media print {
    .green_check_mark_container {
        display: none;
    }
}

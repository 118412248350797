/* DEPRECATED, but we'll keep this around as an admin history view - Eugene 8/15/2024 */

.history_container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
}

.section_header {
    text-align: left;
    width: 100%;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 40px;
}

.essay_textarea {
    width: 762px;
    height: 300px; /* Enough height to display a good portion of text without scrolling */
    padding: 30px; /* Padding for text inside the textarea */

    font-family: "Gothic A1"; /* Readable font */
    font-weight: normal; /* Normal font weight */
    font-size: 18px; /* Comfortable font size */
    line-height: 1.5; /* Line height for better readability */

    border: 1px solid #ccc; /* Subtle border */
    border-radius: 10px; /* Slightly rounded corners */

    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1); /* Soft box shadow for depth */
    background-color: #fff; /* White background */

    resize: vertical; /* Allow only vertical resizing */
    overflow: auto; /* Ensure scrollbar for overflow text */
    margin-bottom: 50px;
}

.section_subtext {
    margin-bottom: 20px;

    a {
        color: rgb(85, 85, 85);
        text-decoration: underline;
        /* font-style: italic; */
    }
}

.model_response_container {
    width: 672px;
    padding: 55px 75px 55px 75px; /* Top, right, bottom, left TRouBLe */
    border: 1px solid black;
    border-radius: 25px;
    margin-bottom: 100px;
}

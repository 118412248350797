.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    font-weight: normal;
    font-size: 0.875rem;

    margin: 10px 0;
    width: 280px;
    height: 320px;

    border: 1px solid #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    transition: border 0.2s ease, box-shadow 0.2s ease;

    &:hover {
        border: 1px solid #0085ff;
        box-shadow: 0 2px 4px rgba(0, 133, 255, 0.2); /* #0085ff */
        cursor: pointer;
    }

    &:active {
        box-shadow: inset 0 0 4px rgba(0, 133, 255, 0.25), 0 2px 4px rgba(0, 133, 255, 0.2); /* #0085ff */
    }

    .card_banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 100%;
        position: relative;
        height: 120px;
        margin: 0 0 20px;

        border-radius: 7px 7px 0 0;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .pro_model_tag_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 12px;
        right: 12px;

        width: 28px;
        height: 28px;
        background: #ffd46e;
        border-radius: 9999px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        overflow: hidden;

        z-index: 1;

        .icon_button {
            padding: 0;
        }

        .trophy_icon {
            width: 100%;
        }
    }

    .floating_logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2px;
        position: absolute;
        top: 70px;
        left: calc(50% - 40px);
        width: 80px;
        height: 80px;

        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .card_body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        padding: 0 25px;
        margin-bottom: 20px;
        position: relative;
        width: 100%;
        flex-grow: 1;

        .action_menu_container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            position: absolute;
            right: 20px;
            bottom: 0px;

            .action_menu_dots {
                width: 32px;
                padding: 4px;
                border-radius: 4px;
                transition: background-color 0.2s ease;

                &:hover {
                    cursor: pointer;
                    /* background-color: #0085ff40; */
                    background-color: aliceblue;
                }
            }

            .action_menu {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                position: absolute;
                top: 32px;
                z-index: 2;
                background-color: white;

                width: fit-content;
                padding: 4px 0;

                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                border-radius: 0 0 4px 4px;
                border: 1px solid aliceblue;
                overflow: hidden;

                .menu_item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;

                    padding: 10px 18px;
                    width: 100%;
                    font-weight: 400;
                    transition: background-color 0.2s ease;

                    &:hover {
                        background-color: aliceblue;
                    }

                    .menu_icon {
                        width: 20px;
                    }

                    .menu_text {
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 1.25;
                    }

                    .delete_text {
                        color: #f44336;
                    }
                }
            }
        }
    }

    .card_title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        width: 100%;
        margin: 1rem 0;
        text-overflow: clip;

        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.25;
        text-align: center;
    }
}

.dashboard_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: clamp(600px, 100%, 940px);
    margin: 0 auto;
    padding: 0 20px;
}

.dashboard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    padding-bottom: 40px;

    .dashboard_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        width: 100%;

        .dashboard_title {
            width: 100%;
            margin: 20px 0;
            flex-grow: 1;
            /* padding: 0 0 0 10px; */

            font-size: 1.75rem;
            font-weight: 600;
        }

        .add_school_button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            padding: 10px 20px;

            font-weight: 500;
            font-size: 0.875rem;
            text-wrap: nowrap;
            color: white;
            border-radius: 4px;
            background: #0085ff;

            transition: filter 0.2s ease;

            &:hover {
                cursor: pointer;
                filter: brightness(1.1);
            }

            img {
                width: 18px;
            }
        }
    }

    .dashboard_dropdown_container {
        width: 240px;
        height: 40px;
        margin: 4px 0 12px;
        flex-shrink: 0;
    }

    .dashboard_content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 30px;

        width: 100%;

        transition: all 0.5s ease;
    }

    .loading_screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        padding-top: 10px;
    }
}

.empty_dashboard_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
    padding-top: 100px;

    .empty_dashboard_image {
        display: inline-block;
        width: 240px;
        margin: 0 0 40px;
    }

    .empty_dashboard_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .empty_dashboard_title {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.25;
            margin: 0 0 10px;
        }

        .empty_dashboard_subtitle {
            font-size: 0.875rem;
            font-weight: 400;
            color: #424242;
            line-height: 1.25;
            margin: 0;
        }

        .empty_dashboard_button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;

            padding: 10px 30px;
            margin: 40px 0;

            font-weight: 500;
            font-size: 0.875rem;
            text-wrap: nowrap;
            color: white;
            border-radius: 4px;
            background: #0085ff;

            transition: filter 0.2s ease;

            &:hover {
                cursor: pointer;
                filter: brightness(1.1);
            }

            img {
                width: 18px;
            }
        }
    }
}

.modify_school_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;

    padding-left: 225px;

    width: 100%;
    height: 100%;
    z-index: 100;

    .modal_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        padding: 20px 50px;
        width: 700px;

        border-radius: 8px;
        background-color: white;
        z-index: 69;
        box-shadow: 0 4px 8px rgb(0, 0, 0, 0.25);

        .dropdown_input_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            margin: 12px 0;
            width: 100%;
            height: 70px;
        }

        .dropdown_label {
            font-size: 0.875rem;
            margin-bottom: 4px;
            font-weight: 500;
        }
    }

    .modal_header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        margin: 20px 0;
        width: 100%;

        .modal_title {
            margin: 0;
            flex-grow: 1;

            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.25;
        }
    }

    .modal_footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;

        margin: 30px 0 20px;
        width: 100%;
    }

    .modal_add_school_button,
    .modal_edit_school_button,
    .modal_delete_school_button,
    .cancel_button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 140px;

        padding: 10px 12px;

        font-weight: 500;
        font-size: 0.875rem;
        text-wrap: nowrap;
        border-radius: 4px;

        img {
            width: 18px;
        }
    }

    .cancel_button {
        padding: 9px 12px; /* Offset y-offset by 1px since we gain 1px from border */
    }

    .modal_add_school_button,
    .modal_edit_school_button {
        color: white;
        background: #0085ff;
        transition: filter 0.2s ease;

        &:hover {
            cursor: pointer;
            filter: brightness(1.1);
        }
    }

    .modal_delete_school_button {
        color: white;
        background: #f44336;
        transition: filter 0.2s ease;

        &:hover {
            cursor: pointer;
            filter: brightness(1.1);
        }
    }

    .cancel_button {
        color: #0085ff;
        background-color: white;
        border: 1px solid #0085ff80;
        transition: background-color 0.2s ease, border-color 0.2s ease;

        &:hover {
            cursor: pointer;
            background-color: #f2f2f2;
            border-color: #0085ff;
        }
    }

    .disabled_add_school_button {
        background: #0085ff;
        cursor: not-allowed;
        filter: brightness(0.8);

        &:hover {
            cursor: not-allowed;
            filter: brightness(0.8);
        }
    }

    .close_button {
        background-color: transparent;
        border-radius: 4px;

        width: 32px;
        height: 32px;
        padding: 4px;

        img {
            width: 100%;
        }

        &:hover {
            cursor: pointer;
            background-color: aliceblue;
        }
    }

    .modal_warning_text {
        font-size: 1rem;
        font-weight: normal;
        margin: 8px 0;

        .modal_warning_text_emphasis {
            font-weight: 600;
        }
    }

    .gray_background {
        display: block;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

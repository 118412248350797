.activity_revision_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;

    max-width: 600px;

    h1,
    h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 16px 0;
    }

    h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 16px 0 8px;
    }
    p {
        font-size: 0.875rem;
        margin: 0 0 8px;
        font-weight: normal;
    }
    strong {
        font-weight: 600;
    }

    li {
        font-size: 0.875rem;
        margin: 0 0 8px;
    }

    ul {
        margin: 0;
    }
}

.background {
    background-color: #ffffff;
    height: 100vh;
    display: grid;

    margin-top: -50px; /* Offset usual margin */
}

.account_type_options_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;

    .account_type_option {
        width: 160px;
        height: 40px;
        border: 1px solid #0085ff;
        border-radius: 8px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        transition: background-color 0.2s ease, color 0.2s ease;
    }

    .account_type_option:hover {
        background-color: #0085ff;
        color: white;
        cursor: pointer;
    }
}

.selected {
    background-color: #0085ff;
    color: white;
}

.setup_body {
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;

    .setup_title {
        margin: 0 0 2rem 0;
    }

    .setup_flow_container {
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .input_field {
        border-radius: 5px;
        padding: 20px;
        border: 0.5px solid black;
    }

    input {
        font-family: "Inter", sans-serif;
        font-weight: normal;
        width: 400px;
        max-width: 800px;
        height: 24px;
        padding: 4px 10px;
        margin: 8px 0;
    }

    .account_setup_textarea {
        width: 540px;
        height: 8rem;
        padding: 10px 12px;
        margin: 8px 0;

        font-family: "Inter", sans-serif;
        font-size: 0.875rem;
        border: 1px solid black;
        border-radius: 4px;
        line-height: 1.5;
        resize: vertical;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    h1 {
        font-size: 3rem;
        margin-bottom: 0;
    }

    p {
        font-size: 1rem;
    }

    h3 {
        font-size: 1.2rem;
    }
}

.setup_submit_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    background-color: #0085ff;
    color: white;
    cursor: pointer;

    width: 160px;
    height: 40px;
    border-radius: 6px;
    margin: 20px 0;

    transition: background-color 0.2s ease;

    .action_button_icon {
        height: 20px;
    }

    .action_button_text {
        font-size: 0.875rem;
        font-weight: 500;
    }

    &:hover {
        background-color: #0095ff;
    }

    &:active {
        transform: scale(0.98);
    }
}

.loading_section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    flex-grow: 1;
}

.loading_spinner {
    width: 50px;
    height: 50px;
}

.main_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    max-width: 800px;
    width: 80%;
    margin: 0 auto 40px;
    padding: 0px 20px 40px;

    font-family: "Inter", sans-serif;

    > div:first-child {
        margin-bottom: 8px;
    }
}

.title {
    font-size: 1.75rem;
    font-weight: 600;
    margin: 20px 0 0 0;
    width: 100%;
}

.subtitle {
    margin: 0 0 30px 0;
    font-style: italic;
    color: rgb(83, 83, 83);
    font-weight: 300;
}

.content_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 30px;
}

.dropdown_input_container {
    width: 240px;
    height: 40px;
    margin: 4px 0 12px;
    flex-shrink: 0;
}

.video_wrapper {
    position: relative;
    width: 100%;
    padding-bottom: calc(100% * 476 / 848); /* Aspect ratio for our youtube video */
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid black;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: none;
}

.quiz_container {
    font-family: "Inter", sans-serif;
}

.title_h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 40px;
    padding-left: 40px; /* Equivalent to p: 5 since each 1 is 8px */
    padding-right: 40px; /* Equivalent to p: 5 since each 1 is 8px */
    padding-top: 20px;
}

.question_title {
    font-family: "Inter", sans-serif;
    margin-bottom: 10px;
}

.radio_group {
    margin-bottom: 10px;
}

.font_inter {
    font-family: "Inter", sans-serif;
}

.result {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid;
    border-radius: 4px;
}

.quiz_complete_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .inner_quiz_complete_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 10px 60px;

        background-color: #dbffe1;
        border-radius: 20px;

        .checkmark_icon {
            width: 25px;
        }
    }

    .certificate_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .certificate_button {
            padding: 10px 24px;
            border-radius: 20px;
            background-color: #0085ff;
            color: white;
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: #0099ff;
                cursor: pointer;
            }
        }
    }
}

.certificate_modal_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;

    padding-left: 225px;

    width: 100%;
    height: 100%;
    z-index: 1;

    animation: fade 0.2s forwards;

    .certificate_modal_button_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

        margin-top: 20px;
        width: 100%;
        background-color: #ffffff;
        z-index: 1000;
        /* border: 20px solid green; */

        .certificate_modal_button {
            padding: 10px 24px;
            border-radius: 20px;
            background-color: #0085ff;
            color: white;
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            transition: background-color 0.2s ease;
            z-index: 1;

            &:hover {
                background-color: #0099ff;
                cursor: pointer;
            }
        }
    }
}

.gray_background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
}

/********************
 *    TYPOGRAPHY    *
 ********************/
html {
    font-size: 16px;
}

h1 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 3.5rem;
    margin-block: 0.85em;
}

h2 {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 2rem;
    margin-block: 0.85em;
}

h3 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 1.75rem;
    margin-block: 0.85em;
}

p {
    font-size: 1.1rem;
    line-height: 1.5;
}

a {
    text-decoration: none;
}

strong {
    font-family: "Inter", sans-serif;
    font-weight: bold;
    /* color: #ff6c0c; */
}

/********************
 *      LAYOUT      *
 ********************/

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-weight: normal;
    font-display: swap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

.App {
    display: flex;
    flex-direction: column;
    /* overflow-x: hidden; */
    overflow-y: auto;
    line-height: 1.5;

    font-family: "Inter", sans-serif;
    height: 100vh;
}

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px max(10vw, 50vw - 500px);
    /* padding: 60px max(10vw, 50vw - 800px); */
    text-align: center;
}

.main_container {
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 0;

    height: 100%;
}

.main_section_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 275px; /* Same as sidebar width. Shitty design, should fix but not trivial since sidebar is "fixed" component*/
    max-width: calc(100% - 275px); /* If above is updated, this also needs to be updated */
    padding-top: 50px;
    max-height: 100%;
}

/* This is the same as above but for the new sidebar, only difference is the margin-left */
.main_section_container_v2 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 225px; /* Same as sidebar width. Shitty design, should fix but not trivial since sidebar is "fixed" component*/
    max-width: calc(100% - 225px); /* If above is updated, this also needs to be updated */
    padding-top: 50px;
    max-height: 100%;
}

.adjust_margin {
    margin-left: 0px;
    max-width: 100%;
    padding-top: 0px;
}

.loading_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    .loading_spinner {
        opacity: 0;
        width: 64px;
        height: 64px;

        animation: fadeIn 1s forwards;
    }
}

.error_boundary_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    .error_message {
        font-size: 1rem;
        margin-top: 100px;
        font-weight: 400;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.1;
    }
}

@media print {
    .main_section_container {
        margin-left: 0px;
        max-width: 700px;
    }
}

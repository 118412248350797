/* DEPRECATED PAGE IS NO LONGER DISPLAYED - Eugene 8/15/2024 */

.screen2_grading_form_container {
    margin-top: 25px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}

.screen2_grading_form_title {
    font-family: "Gothic A1";
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    width: 800px;
}

.screen2_grading_form_subtitle {
    font-family: "Gothic A1";
    font-weight: bolder;
    text-align: center;
    font-size: 1.5rem;
    width: 800px;
    margin-top: 50px;
}

.screen2_grading_form {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.screen2_input_field_title {
    text-align: left;
    font-size: 1.5rem;
}

.screen2_input_field_title_subtext {
    width: 762px;
    margin-top: -10px;
    margin-bottom: 20px;
    color: rgb(89, 89, 89);
}

.if_applicable_text {
    font-size: 1rem;
    color: darkgray;
    font-weight: normal;
}

.screen2_prompt_input_field {
    width: 762px;
    height: 100px; /* Enough height to display a good portion of text without scrolling */
    padding: 30px; /* Padding for text inside the textarea */

    font-family: "Gothic A1"; /* Readable font */
    font-weight: normal; /* Normal font weight */
    font-size: 18px; /* Comfortable font size */
    line-height: 1.5; /* Line height for better readability */

    border: 1px solid #ccc; /* Subtle border */
    border-radius: 10px; /* Slightly rounded corners */

    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1); /* Soft box shadow for depth */
    background-color: #fff; /* White background */

    resize: vertical; /* Allow only vertical resizing */
    overflow: auto; /* Ensure scrollbar for overflow text */
    margin-bottom: 50px;
}

.screen2_prompt_input_field:focus {
    outline: none;
    border: 1px solid #000000;
}

.screen2_essay_input_field {
    width: 762px;
    height: 300px; /* Enough height to display a good portion of text without scrolling */
    padding: 30px; /* Padding for text inside the textarea */

    font-family: "Gothic A1"; /* Readable font */
    font-weight: normal; /* Normal font weight */
    font-size: 18px; /* Comfortable font size */
    line-height: 1.5; /* Line height for better readability */

    border: 1px solid #ccc; /* Subtle border */
    border-radius: 10px; /* Slightly rounded corners */

    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1); /* Soft box shadow for depth */
    background-color: #fff; /* White background */

    resize: vertical; /* Allow only vertical resizing */
    overflow: auto; /* Ensure scrollbar for overflow text */
    margin-bottom: 50px;
}

.screen2_essay_input_field:focus {
    outline: none;
    border: 1px solid #000000;
}

.screen2_field_container {
    width: 762px;
}

.screen2_max_word_count_input_field {
    width: 100px;
    height: 0px;
    padding: 25px 30px 25px 30px; /* Padding for text inside the textarea */

    font-family: "Gothic A1"; /* Readable font */
    font-weight: normal; /* Normal font weight */
    font-size: 18px; /* Comfortable font size */
    line-height: 1.5; /* Line height for better readability */

    border: 1px solid #ccc; /* Subtle border */
    border-radius: 10px; /* Slightly rounded corners */

    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1); /* Soft box shadow for depth */
    background-color: #fff; /* White background */

    resize: vertical; /* Allow only vertical resizing */
    overflow: auto; /* Ensure scrollbar for overflow text */
    margin-bottom: 50px;
}

.screen2_max_word_count_input_field:focus {
    outline: none;
    border: 1px solid #000000;
}

.screen2_grade_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px !important;
    transition: background-color 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.screen2_grade_request_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-width: 800px;
}

.section_subtext {
    margin-bottom: 20px;

    a {
        color: rgb(85, 85, 85);
        text-decoration: underline;
        /* font-style: italic; */
    }
}

.screen2_grading_model_response_container {
    width: 672px;
    padding: 55px 75px 55px 75px; /* Top, right, bottom, left TRouBLe */
    border: 1px solid black;
    margin-top: 40px;
    border-radius: 25px;
}

.grade_error_message {
    margin-top: -20px;
    text-align: center;
    max-width: 40%;
    border: 1px solid red;
    background-color: pink;
    padding: 15px;
    border-radius: 10px;
    color: rgb(78, 78, 78);
}

.bold_font {
    font-weight: bold;
}

@media print {
    .screen2_essay_input_field {
        min-width: 500px;
        max-width: 500px;
    }

    .screen2_prompt_input_field {
        min-width: 500px;
        max-width: 500px;
    }
}

.demo_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 50px;
    width: 100%;

    gap: 20px;
    /* background: lightcoral; */
}

.demo_title {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 1.75rem;
    margin: 0 0 20px 0;
}

.item {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 20px;
    border: 1px solid black;
    background: white;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    padding: 10px 30px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    transition: border 0.5s ease;

    .card_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        width: 100%;
        font-size: 0.875rem;
        font-weight: 400;

        .card_header_title {
            flex-grow: 1;
            font-size: 1.125rem;
            font-weight: 500;
            margin: 8px 0;
        }

        .dropdown_arrow {
            width: 24px;
            height: 24px;
            transition: transform 0.2s ease-out;
        }

        .rotated_dropdown_arrow {
            transform: rotate(180deg);
        }
    }

    .card_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;

        width: 100%;
        padding: 20px 0;

        border-top: 1px solid #0085ff40;
    }
}

.disabled_card {
    pointer-events: none;
    background-color: #f0f0f0;
}

.essay_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    > p {
        font-size: 0.875rem;
        font-weight: 400;
        margin: 0;
    }

    .essay_content_title {
        font-size: 1rem;
        font-weight: 500;
        margin: 10px 0;
    }
}

.swipeable_div {
    width: 700px;
    cursor: grabbing;
}

.disabled_swipeable_div {
    pointer-events: none;
}

.highlighted_card_border {
    border: 1px solid #0085ff;
}

.go_back {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    cursor: pointer;
    background-color: #0085ff;
    padding: 8px 24px;
    border-radius: 4px;
}

.loading_image {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px;
    width: 100%;
    font-size: 0.875rem;
}

.grade_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding: 0 20px;

    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;

    .submitted_essay {
        padding: 30px;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        transition: border 0.5s ease;
        max-width: 50%;

        animation: fadeInAndUp 1s forwards;
    }

    .grade_results {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        opacity: 0; /* Animation starts out invisible */
        padding: 30px;
        max-width: 50%;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        transition: border 0.5s ease;

        animation: fadeInAndUp 1s forwards;
        animation-delay: 0.2s;

        font-size: 1rem;
    }

    .header {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
    }
}

/* Uniform across ReactMarkdown secions */
.model_response_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;

    h1,
    h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 16px 0;
    }

    h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 16px 0 8px;
    }
    p {
        font-size: 0.875rem;
        margin: 0 0 8px;
    }
    strong {
        font-weight: 600;
    }

    li {
        font-size: 0.875rem;
        margin: 0 0 8px;
    }

    ul {
        margin: 0;
    }
}

@keyframes fadeInAndUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    50% {
        opacity: 0.75;
        transform: translateY(5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

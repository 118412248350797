.loading_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
}

.loading_spinner {
    width: 64px;
    height: 64px;
}

.loading_text {
    margin-top: 5px;
    color: rgb(120, 120, 120);
    text-align: center;
    margin-bottom: 30px;
}

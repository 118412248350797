.section_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    width: 100%;
    margin-bottom: 30px;
}

.section_title {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
}

.input_method_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    gap: 20px;

    .input_method_option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        gap: 4px;

        padding: 10px 20px;
        background-color: white;
        border-radius: 5px;
        font-size: 0.875rem;
        font-weight: 500;
        border: 1px solid #d2d2d2;
        transition: background-color 0.2s ease, border 0.2s ease;

        &:hover {
            cursor: pointer;
            background-color: #f0f8ff;
            border: 1px solid #0085ff;
        }
    }

    .input_method_option_active {
        border: 1px solid #0085ff;
        background-color: #f0f8ff;
    }
}

.getting_started_list {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
}

.connect_to_google_drive_container {
    margin-bottom: 20px;
}
.connect_to_google_drive_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    width: 50%;
    padding: 10px 20px;
    background-color: white;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid #d2d2d2;
    transition: background-color 0.2s ease, border 0.2s ease;

    &:hover {
        background-color: #f0f8ff;
        border: 1px solid #0085ff;
    }
}

.google_drive_icon {
    width: 20px;
    height: 20px;
}

.doc_preview_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    max-width: 50%;
    padding: 12px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    transition: border 0.2s ease;

    &:hover {
        cursor: pointer;
        border: 1px solid #0085ff;
    }

    .doc_preview_card_icon {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
    }

    .doc_preview_card_close_icon {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 10px;
        padding: 4px;
        transition: background-color 0.2s ease;

        &:hover {
            cursor: pointer;
            background-color: #e0f0ff;
        }
    }

    .doc_preview_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .doc_preview_card_title {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
            flex-grow: 1;

            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .doc_preview_card_preview_link {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
            color: #0085ff;
            text-decoration: none;

            &:hover {
                cursor: pointer;
                color: #0099ff;
                text-decoration: underline;
            }
        }
    }
}

.input_options_bar {
    border: 1px solid #d2d2d2;
    border-radius: 0 0 5px 5px;
    padding: 10px;
    width: 100%;
}

.submission_details_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .submission_details {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 4px 0;
    }

    .detail {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 4px;
        margin: 0;

        .detail_label {
            font-size: 0.875rem;
            font-weight: 600;
            flex-shrink: 0;
            margin: 0;
        }

        .detail_value {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
            word-break: break-word;

            display: -webkit-box;
            line-clamp: 3;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .restart_button {
        padding: 8px 16px;
        font-size: 0.875rem;
        font-weight: 400;
        border-radius: 5px;
        background-color: #0085ff;
        color: white;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0099ff;
        }
    }
}

.essay_feedback_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* border: 1px solid #d2d2d2; */

    width: 100%;

    .essay_data_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-grow: 1;
        max-width: 50%;

        .grade_button {
            padding: 8px 16px;
            font-size: 0.875rem;
            font-weight: 500;
            border-radius: 5px;
            background-color: white;
            color: #0085ff;
            border: 1px solid #0085ff;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #f0f8ff;
            }
        }

        .disabled {
            background-color: #d2d2d2;
            border: 1px solid #d2d2d2;
            color: white;
            cursor: not-allowed;

            &:hover {
                background-color: #d2d2d2;
                cursor: not-allowed;
            }
        }
    }

    .essay_data_title {
        font-size: 0.875rem;
        font-weight: 400;
        margin: 0;
    }

    .essay_review_container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex-grow: 1;
        max-width: 50%;

        .essay_review_title {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
        }

        .essay_review_text {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
            word-break: break-word;
            white-space: pre-wrap;
        }
        .essay_feedback {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            width: 100%;

            .loading_image {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
            }

            .essay_feedback_content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                flex-grow: 1;

                width: 100%;

                .essay_feedback_text {
                    font-size: 0.875rem;
                    margin: 0 0 8px;
                }

                .reveal_div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;
                    cursor: pointer;
                    color: gray;
                    transition: color 0.2s ease;

                    &:hover {
                        color: black;
                    }
                }

                .in_depth_review_container {
                    transition: height 0.5s ease-out;
                    overflow: hidden;
                }

                .review_shown {
                    height: 100%;
                }

                .review_hidden {
                    height: 0%;
                }
            }

            .note_container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin: 10px 0;

                .note {
                    font-size: 0.875rem;
                    font-style: italic;
                    margin: 4px 0;
                }
            }

            .grade_error_message {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                flex-grow: 1;
                background-color: #e74c3c;
                color: white;
                margin: 20px auto;
                width: clamp(200px, 60%, 400px);
                padding: 16px 16px;
                border-radius: 6px;
                text-align: center;
            }
        }
    }

    .editable_card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border: 1px solid #d2d2d2;
        border-radius: 4px;
        padding: 12px;
        transition: border 0.2s ease;
        position: relative;

        &:hover {
            cursor: pointer;
            border: 1px solid #0085ff;
        }

        .editable_card_label {
            font-size: 0.875rem;
            font-weight: 600;
            margin: 0 0 4px 0;
        }

        .editable_card_textarea {
            box-sizing: border-box;
            width: 100%;
            height: 300px;
            padding: 10px;
            margin: 4px 0;

            font-size: 0.875rem;
            font-family: inherit;
            line-height: 1.5;

            border: 1px solid #d2d2d2;
            border-radius: 5px;
            resize: none;
        }

        .icon_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;

            position: absolute;
            top: 10px;
            right: 10px;
        }

        .icon {
            width: 20px;
            height: 20px;
            transition: transform 0.2s ease, opacity 0.2s ease;

            &:hover {
                cursor: pointer;
                opacity: 0.5;
            }
        }

        .rotated_chevron {
            transform: rotate(180deg);
        }
    }

    .essay_prompt {
        font-size: 0.875rem;
        font-weight: 400;
        margin: 0;
        word-break: break-word;
        white-space: pre-wrap;
    }

    .essay_response {
        font-size: 0.875rem;
        font-weight: 400;
        margin: 0;
    }

    .collapsed {
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.divider {
    border-top: 1px solid #d2d2d2;
    margin: 20px 0;
    width: 100%;
    height: 1px;
}

/* Uniform across ReactMarkdown sections */
.model_response_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;

    h1,
    h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 16px 0;
    }

    h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 16px 0 8px;
    }
    p {
        font-size: 0.875rem;
        margin: 0 0 8px;
        font-weight: 400; /* This diverges from other ReactMarkdown sections*/
    }
    strong {
        font-weight: 600;
    }

    li {
        font-size: 0.875rem;
        margin: 0 0 8px;
    }

    ul {
        margin: 0;
    }
}

.grading_breakdown_parent {
    position: relative;
}

.position_overlay {
    position: absolute;
    top: 25px;
    left: 225px;
    background-color: transparent;
}

.info_icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-color: transparent;
}

.loading_spinner_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.loading_spinner {
    width: 50px;
    height: 50px;
    opacity: 0;

    animation: appear 0.5s ease-out 0.2s;
}

.direct_input_container,
.link_input_container {
    width: 100%;
    animation: unblur 0.3s ease-out;
}

@keyframes unblur {
    from {
        filter: blur(1px);
        opacity: 0.1;
    }
    to {
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.25;
    }
    100% {
        opacity: 1;
    }
}

.base_button {
    font-family: "Inter", sans-serif;
    font-weight: normal;
    border: none;
    transition: transform 0.35s;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.base_button:hover {
    cursor: pointer;
}

.loading_spinner {
    max-width: 20px;
    max-height: 20px;
}

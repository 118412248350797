.new_background {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.new_background_buy_now_flow {
    height: 100%;
    min-height: calc(100vh - 200px); /* Hardcoded for a 200px top bar total rip. */
    display: flex;
    flex-direction: column;
    padding-bottom: 75px;
}

.auth_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    width: 100%;
    max-width: 360px;
    margin: 0 auto;

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;

        label {
            font-size: 0.75rem;
            display: inline-block;
            font-weight: 400;
        }

        input {
            padding: 10px 12px;
            margin: 6px 0;
            width: 100%;
            border-radius: 4px;
            border: none;
            outline: 1px solid #333333;

            font-weight: normal;
            font-size: 0.875rem;
            text-align: bottom;
            transition: outline 0.2s ease;

            &:focus {
                outline: 1px solid #0085ff;
            }
        }

        .form_input {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 8px;
            width: 100%;
        }

        .auth_action_button {
            background-color: #0085ff;
            color: white;
            cursor: pointer;

            width: 100%;
            height: 2.4rem;
            font-size: 0.875rem;
            border-radius: 4px;
            margin: 20px 0;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            transition: background-color 200ms ease-out;

            &:hover {
                background-color: #0099ff;
            }

            &:active {
                transform: scale(0.98);
            }
        }

        .error {
            color: red;
            font-size: 0.875rem;
        }
    }

    .auth_page_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 0 30px 0;

        > img {
            width: 32px;
            cursor: pointer;
            margin: 20px 0;
        }

        > h1 {
            margin: 0;
            font-size: 1.75rem;
            text-align: center;
            font-weight: 600;
        }

        > p {
            font-size: 0.875rem;
            text-align: center;
            color: #888888;
            font-weight: 400;
        }
    }

    .login_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .login_form_inputs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
        }

        .login_divider_container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            width: 100%;

            .login_horizontal_divider {
                border-bottom: 1px solid #aaaaaa;
                height: 0px;
                width: 100px;
                flex-grow: 1;
            }

            .login_horizontal_divider_text {
                font-size: 0.875rem;
                margin: 10px 30px;
            }
        }

        .login_method_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            margin: 0.5rem 0;
            width: 100%;
        }

        .login_footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            margin: 40px 0 0 0;
            font-size: 0.875rem;
            color: #888888;
        }
    }

    .signup_body {
        .signup_options {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            margin: 20px 0;

            .signup_button {
                width: 100%;
                padding: 8px 0;
                background-color: white;
                border: 1px solid #333333;
                border-radius: 4px;
                transition: background-color 200ms ease-out;
            }

            .signup_button:hover {
                background-color: #eeeeee;
            }
        }

        .signup_form_inputs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;

            .another_method_text {
                font-size: 0.875rem;
                font-weight: 600;
                color: #888888;
                transition: color 200ms ease-out;
            }
            .another_method_text:hover {
                color: #000000;
                cursor: pointer;
            }
        }

        .signup_footer {
            margin: 80px 0 0;
            text-align: center;
            font-size: 0.875rem;
            color: #888888;
        }
    }

    .auth_button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        width: 100%;
        height: 44px;
        padding: 4px 16px;

        background-color: white;
        border-radius: 4px;
        border: 1px solid #d2d2d2;
        transition: background-color 0.2s ease;

        .auth_button_icon {
            height: 20px;
            width: 20px;
        }

        .auth_button_text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-grow: 1;
            font-size: 0.875rem;
        }

        &:hover {
            background-color: aliceblue;
            border-color: #0085ff40;
        }
    }

    .forgot_password_body {
        .forgot_password_form_inputs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
        }

        .forgot_password_sent_email_text {
            text-align: center;
            font-size: 1rem;
            padding: 20px 0;
        }

        .forgot_password_footer {
            margin: 80px 0 0;
            text-align: center;
            font-size: 0.875rem;
            color: #888888;
        }
    }

    .reset_password_body {
        .reset_password_form_inputs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
        }

        .reset_password_footer {
            margin: 80px 0 0;
            text-align: center;
            font-size: 0.875rem;
            color: #888888;
        }
    }
}

.background {
    background-image: url("../../imgs/login_page_background.png");
    background-size: cover;
    height: 100vh;
    display: grid;

    /* Handle reversing usual margins when sidebar is displayed*/
    margin-left: -275px;
    margin-top: -50px;
}

.link {
    color: inherit;
    font-weight: 600;
    transition: color 200ms ease-out;
    text-decoration: underline;
    cursor: pointer;
}

.link:hover {
    color: black;
}

a {
    color: #89cff0;
}

@media screen and (max-width: 480px) {
    .login_modal {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
}

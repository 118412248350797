.banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-bottom: 25px;
    width: 100%;
    text-align: center;
    background-color: #ff6961;
    color: #ffffff;
    padding: 10px 50px 10px 10px;

    .close_button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 18px;
        height: 18px;
        padding: 0;
    }
}

.hidden {
    display: none;
}

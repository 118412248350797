.tooltip_container {
    position: relative;
    display: inline-block;

    &:hover .tooltip {
        visibility: visible;
    }
}

.tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    position: absolute;
    /* top: 0; */
    /* left: 50%; */
    /* transform: translate(-50%, -120%); */

    max-width: 140px;
    /* width: 140px; */
    padding: 10px 12px;
    font-size: 0.75rem;

    background: linear-gradient(to top right, #0085ff, #00b0ff);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-weight: 500;
    color: white;
    line-height: 1.25;
    text-align: center;
    /* visibility: hidden; */
    animation: fadeIn 0.2s ease;

    p {
        line-height: 1.25;
        font-size: 0.875rem;
        font-weight: 500;
        margin: 0;
        text-align: start;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.arrow {
    transform: translateY(-1%);
}

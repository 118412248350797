.athena_sidebar_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    width: 500px;
    height: 100%;

    border-left: 1px solid #0085ff40;
    /* box-shadow: -2px 0 2px rgba(0, 101, 255, 0.25); */

    background-color: white;
    font-weight: normal;
    font-size: 0.875rem;
    word-wrap: break-word;

    transition: width 0.5s ease;

    .sidebar_header {
        height: 56px;
    }
}

.open_sidebar {
    width: 500px;
}

.closed_sidebar {
    border: none;
    width: 0;
}

.sidebar_content_container {
    display: flex;
    position: relative;
    overflow: auto;
    width: 500px;
    height: 100%;
}

/* 
 * 1500px seems to be where the right sidebar starts getting cut off
 * We need to change this value after we figure out final toolbar width
 * Keep in sync with Application.module.css
 */
@media screen and (max-width: 1500px) {
    .athena_sidebar_container {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 70;
    }
}

.tabs_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    gap: 10px;
    width: 100%;
    font-weight: 500;

    border-bottom: 1.5px solid #d2d2d2;

    overflow: hidden;

    .tab {
        color: #424242;
        cursor: pointer;
        padding: 8px 10px;
        border-bottom: 3px solid transparent;
        text-wrap: nowrap;
    }

    .active_tab {
        position: relative;
        color: #0085ff;
        border-bottom-color: #0085ff;
    }
}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 4px;

    transition: background-color 0.1s ease-out;
    animation: fadeIn 1s forwards;

    &:hover {
        background-color: #d6eaff;
        cursor: pointer;
    }

    .button_icon {
        width: 24px;
        height: 24px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.close_button {
    margin: 8px;
}

/* 
 * ESSAY GUIDANCE MAIN TAB CSS
 */
.athena_chat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    flex-grow: 1;
    padding: 8px 12px;
    width: 100%;
    overflow: auto;
    background-color: #f0f8ff;

    .athena_chat_container {
        display: flex;
        /* Need to do reverse because column would force us to use justify-content: flex-end, but there's a bug
        in Chrome that doesn't allow us to scroll when the flexbox has justify-content: flex-end */
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        flex-grow: 1;

        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }

    .loading_image {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 20px;
        width: 100%;
    }

    .athena_chat_input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: calc(100% - 8px);
        /* border: 1px solid #d2d2d2; */
        border-radius: 4px;
        background-color: white;
        margin: 4px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

        textarea {
            width: 100%;
            max-height: 100px;
            padding: 8px 12px;
            border: none;
            border-radius: 4px;
            resize: none;

            line-height: 1.5;

            font-family: "Inter", sans-serif;

            &:focus {
                outline: none;
            }
        }
    }

    .message_bubble_container,
    .user_message_bubble_container {
        display: flex;
        flex-direction: column;
        /* align-items defined below */
        justify-content: flex-start;

        margin-bottom: 4px;
        padding: 0 4px; /* Need extra padding to prevent shadow from clipping */

        width: 100%;

        .message_bubble {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 12px;

            text-wrap: wrap;

            /* max-width: 90%; */
            width: 100%; /* Making width 100% because users can't send messages */
            background-color: white;
            padding: 16px;
            border-radius: 4px;
            /* border: 1px solid gray; */
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

            animation: fade 0.2s forwards;

            .message_bubble_message {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 0;
                width: 100%;

                h1,
                h2 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin: 16px 0;
                }

                h3 {
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin: 16px 0 8px;
                }
                p {
                    font-size: 0.875rem;
                    margin: 0 0 8px;
                }
                strong {
                    font-weight: 600;
                }
            }
        }

        .message_bubble_header,
        .user_message_bubble_header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            .message_bubble_icon,
            .user_message_bubble_icon {
                max-width: 24px;
                max-height: 24px;
                border-radius: 50%;
            }
            .message_bubble_icon {
                order: 1;
            }
            .user_message_bubble_icon {
                order: 3;
            }

            .message_bubble_sender,
            .user_message_bubble_sender {
                font-weight: 600;
                flex-grow: 1;
            }
            .message_bubble_sender {
                order: 2;
            }
            .user_message_bubble_sender {
                order: 1;
            }

            .message_bubble_message_type,
            .user_message_bubble_message_type {
                font-weight: 600;
                color: #0085ff;
            }
            .message_bubble_message_type {
                order: 3;
            }
            .user_message_bubble_message_type {
                order: 1;
            }
        }

        .message_bubble_header,
        .user_message_bubble_header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

        .message_bubble_header {
            justify-content: flex-start;
        }

        .user_message_bubble_header {
            justify-content: flex-end;
        }
    }

    .message_bubble_container {
        align-items: flex-start;
    }

    .user_message_bubble_container {
        align-items: flex-end;
    }

    .athena_chat_footer {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 0 4px;
        width: 100%;

        .athena_chat_notice {
            width: 100%;
            margin: 0;
            flex-grow: 1;

            color: #808080;
            font-size: 0.75rem;

            .athena_chat_background_link {
                color: #0085ff;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .clear_history_button {
            font-size: 0.75rem;
            background: none;
            text-wrap: nowrap;
            padding-bottom: 2px; /* Button text isn't aligned with other text */
        }
    }
}

/*
 * ESSAY REVIEWS MAIN TAB CSS
 */

.athena_essay_reviews {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    flex-grow: 1;

    width: 100%;
    height: 100%;
    padding: 8px 16px;

    background-color: aliceblue;

    overflow: hidden;

    > div {
        background-color: white;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        animation: fade 0.2s forwards;
    }

    .essay_submission {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        max-height: 40%;
        flex: 0 0 auto;

        overflow: hidden;

        .essay_submission_header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            width: 100%;
            padding: 12px 16px;
            gap: 12px;

            &:hover {
                cursor: pointer;
            }
        }

        .essay_submission_content {
            font-weight: normal;
            white-space: pre-wrap;

            width: 100%;
            padding: 8px 16px 16px 24px;
            overflow: auto;
        }
    }

    .essay_feedback_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        flex: 1 1 auto;

        padding: 16px;
        width: 100%;
        min-height: 20%;
        overflow-y: auto;

        background: white;
        border-radius: 6px;
        border-bottom: 1px solid aliceblue;

        .essay_feedback {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            width: 100%;

            .essay_feedback_header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                width: 100%;

                .sidebar_pro_upsell_container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;

                    background-color: #0085ff;
                    border-radius: 6px;
                    width: 100%;
                    color: white;

                    padding: 8px 12px;
                    margin-bottom: 10px;

                    .pro_user_notice {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        width: 100%;

                        > p {
                            font-size: 0.875rem;
                            margin: 0;
                        }
                    }

                    .sidebar_pro_upsell {
                        flex-grow: 1;
                    }

                    .upgrade_button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 4px;

                        padding: 8px 12px;
                        border-radius: 4px;
                        background-color: white;
                        color: #0085ff;
                        text-wrap: nowrap;

                        .action_button_icon {
                            width: 16px;
                        }

                        .action_button_text {
                            font-weight: 500;
                        }

                        &:hover {
                            background-color: #f4f4f4;
                            outline: 1px solid #808080;
                        }
                    }
                }

                .essay_feedback_timestamp {
                    font-size: 0.75rem;
                    color: #808080;
                }
            }

            .loading_image {
                display: flex;
                flex-direction: column;
                align-items: center;

                padding: 20px 0;
                width: 100%;
            }

            .note_container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin: 0;

                .note_header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 4px;

                    margin: 0 0 10px;
                    color: rgb(85, 85, 85);
                    transition: color 0.2s ease;

                    &:hover {
                        cursor: pointer;
                        color: #808080;
                    }

                    .note_header_text {
                        font-weight: 500;
                        font-size: 0.875rem;
                        margin: 0;
                    }

                    .chevron {
                        width: 20px;
                        height: 20px;
                    }

                    .rotated_chevron {
                        transform: rotate(180deg);
                    }
                }

                .notes {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    width: 100%;
                }

                .note {
                    font-size: 0.875rem;
                    font-style: italic;
                    margin: 4px 0;
                }
            }

            .grade_error_message {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                flex-grow: 1;
                background-color: #e74c3c;
                color: white;
                margin: 20px auto;
                width: clamp(200px, 60%, 400px);
                padding: 16px 16px;
                border-radius: 6px;
                text-align: center;
            }
        }

        .no_essay_feedback {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            flex-grow: 1;

            .no_essay_feedback_title {
                font-weight: 600;
                font-size: 1.5rem;
                margin: 0 0 8px;
            }

            .no_essay_feedback_text {
                margin-top: 0;
                font-size: 0.875rem;
                max-width: 300px;
                text-align: center;
            }

            .grade_button {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 6px;

                font-family: "Inter", sans-serif; /* Forcing here for now, will replace in base button later */
                font-weight: 600;
                background: #0085ff;
                color: white;

                border-radius: 4px;
                margin-top: 12px;
                padding: 10px 24px 10px 20px;
                cursor: pointer;

                transition: filter 0.2s ease;

                &:hover {
                    cursor: pointer;
                    filter: brightness(1.1);
                }

                .grade_icon {
                    width: 16px;
                }
            }
        }

        .no_essay_feedback_img {
            display: inline-block;
            width: 50%;
            margin: 0 auto;
        }
    }

    .previous_submissions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        margin-bottom: 10px;

        overflow: hidden;
        flex: 0 0 auto;
        max-height: 40%;

        .previous_submissions_header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            padding: 12px 16px;
            width: 100%;
        }

        .previous_submissions_list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            padding: 0px 16px;
            margin: 0;
            width: 100%;
            overflow-y: auto;

            list-style-type: none;

            .previous_submissions_list_item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                /* border: 1px solid brown; */
                width: 100%;
                padding: 12px 8px;
                border-bottom: 1px solid aliceblue;

                transition: background-color 0.2s ease-out;

                .submission_info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    width: calc(100% - 24px); /* too tired to figure out how to not hardcode this */
                    flex-grow: 1;

                    /* width: calc(100% - 30px); */
                }

                .submission_preview {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    width: 100%;
                    /* flex-grow: 1; */
                }

                .submission_date {
                    font-size: 0.75rem;
                    color: #424242;
                    /* flex-grow: 1; */
                }

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 4px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: aliceblue;
                    border-radius: 4px;
                }
            }
        }

        .no_previous_submissions {
            padding: 8px 16px 16px;
            width: 100%;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .previous_submission_display_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        border-radius: 0;
        background-color: aliceblue;

        z-index: 10; /* Make sure this container is above rest of essay reviews */

        .submission_header {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            background-color: white;
            position: relative;

            padding: 16px;
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 1px solid #0085ff40;
            /* flex-shrink: 0;
            overflow: hidden; */

            .submission_info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                position: relative;

                overflow: hidden;
                width: 100%;
            }

            .submission_name {
                font-weight: 600;
                font-size: 0.875rem;
                width: 100%;

                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .submission_timestamp {
                font-weight: normal;
                font-size: 0.75rem;
                color: #424242;
                width: 100%;
            }
        }

        .submission_body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            width: 100%;
            height: 100%;
            padding: 16px;
            overflow: hidden;
        }

        .submission_essay_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            width: 100%;
            height: 50%;
            padding: 16px;
            overflow: auto;

            background-color: white;
            border-radius: 4px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

            .submission_essay_title {
                font-weight: 600;
                font-size: 0.875rem;
                margin-bottom: 10px;
            }

            .submission_essay_text {
                font-size: 0.875rem;
                white-space: pre-wrap;
            }
        }

        .submission_model_response_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            /* flex-grow: 1; */

            width: 100%;
            height: 50%;
            margin-top: 20px;
            padding: 16px;
            overflow: auto;

            background-color: white;
            border-radius: 4px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

            .submission_model_response_header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                width: 100%;
                margin-bottom: 10px;

                .submission_model_response_title {
                    font-weight: 600;
                    font-size: 0.875rem;
                    flex-grow: 1;
                }

                .submission_model_response_athena_model {
                    font-weight: 600;
                    font-size: 0.875rem;
                    color: #0085ff;
                }
            }

            .export_section {
                margin-top: 0;
            }

            .submission_model_response_text {
                font-size: 0.875rem;
                white-space: pre-wrap;
            }
        }
    }

    .chevron {
        width: 24px;
        height: 24px;
        margin-top: 2px;
        transition: transform 0.2s ease-out;
    }

    .rotated_chevron {
        transform: rotate(180deg);
    }
}

/*
 * NEXT STEPS SUBTAB CSS
 */

.next_steps_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    background-color: aliceblue;
    width: 100%;
    flex-grow: 1;
    padding: 8px 16px 24px;
    overflow: auto;

    .tip {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;

        width: 100%;
        padding: 16px;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        border: 1px solid white;
        transition: border 0.2s ease;

        .quoted_text {
            font-style: italic;
        }

        > p {
            margin: 0;
            font-weight: 400;
            font-size: 0.875rem;
        }

        &:hover {
            cursor: pointer;
            border: 1px solid #0085ff;
        }
    }
}

/* Uniform across ReactMarkdown sections */
.model_response_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;

    h1,
    h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 16px 0;
    }

    h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 16px 0 8px;
    }
    p {
        font-size: 0.875rem;
        margin: 0 0 8px;
    }
    strong {
        font-weight: 600;
    }

    li {
        font-size: 0.875rem;
        margin: 0 0 8px;
    }

    ul {
        margin: 0;
    }
}

.sidebar_section_title {
    font-weight: 500;
    font-size: 0.875rem;
    flex-grow: 1;
}

.sidebar_section_subtitle {
    font-size: 0.75rem;
    color: #808080;
}

/*
 * Google Docs Comments
 */

.comments_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    .comments_title {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 16px 0;
    }

    .comments_body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;

        width: 100%;
        margin: 8px 0;

        .comments_notice {
            font-size: 0.875rem;
            font-weight: 400;
        }
    }

    .comment {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;

        width: 100%;
        padding: 16px 12px;
        border-radius: 4px;
        border: 1px solid #d2d2d2;
        overflow: hidden;
        transition: border-color 0.2s ease;

        &:hover {
            border-color: #0085ff;
        }

        .quote {
            font-style: italic;
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
            width: 100%;
        }

        .feedback {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
            width: 100%;
        }

        .comment_action_bar {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 4px;

            width: 100%;
        }

        .icon_button {
            width: 32px;
            height: 32px;
            padding: 6px;
            position: relative;
            border-radius: 16px;
            background-color: white;
            transition: background-color 0.2s ease;

            &:hover {
                cursor: pointer;
                background-color: #f4f4f4;
            }
        }

        .icon {
            width: 20px;
            height: 20px;
        }
    }
}

/*
* Subgrade CSS
*/
.rating_excellent {
    color: darkgreen;
    background-color: #d4edda; /* Light green background */
    padding: 2px 6px;
    border-radius: 4px;
}

.rating_okay {
    color: #856404;
    background-color: #fff3cd; /* Light yellow background */
    padding: 2px 6px;
    border-radius: 4px;
}

.rating_needs_work {
    color: darkred;
    background-color: #f8d7da; /* Light red background */
    padding: 2px 6px;
    border-radius: 4px;
}

.grading_breakdown_parent {
    position: relative;
}

.position_overlay {
    position: absolute;
    top: 25px;
    left: 225px;
    background-color: transparent;
}

.info_icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-color: transparent;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #d2d2d2;
    margin: 24px 0;
    flex-shrink: 0;
}

.essay_feedback_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;

    width: 100%;

    .essay_feedback_title {
        font-weight: 600;
        font-size: 1.5rem;
        margin: 0 0 8px;
    }

    .essay_feedback_text {
        font-size: 0.875rem;
        margin: 0 0 8px;
    }

    .reveal_div_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;

        .icon_button {
            width: 32px;
            height: 32px;
            padding: 6px;
            position: relative;
            border-radius: 16px;
            background-color: white;
            transition: background-color 0.2s ease;

            &:hover {
                cursor: pointer;
                background-color: #f4f4f4;
            }
        }

        .icon {
            width: 20px;
            height: 20px;
        }
    }

    .reveal_div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        cursor: pointer;
        color: gray;
        transition: color 0.2s ease;

        &:hover {
            color: black;
        }
    }

    .in_depth_review_container {
        transition: height 0.5s ease-out;
        overflow: hidden;
    }

    .review_shown {
        height: 100%;
    }

    .review_hidden {
        height: 0%;
    }
}

.export_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    margin-top: 12px;

    .section_title {
        font-weight: 600;
        font-size: 1.25rem;
        margin: 0 0 8px;
    }

    .export_button_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        width: 100%;

        .export_button {
            padding: 8px 16px;
            background-color: #0085ff;
            color: white;
            border-radius: 4px;
            transition: background-color 0.2s ease;

            &:hover {
                cursor: pointer;
                background-color: #0099ff;
            }

            &:active {
                background-color: #0085ff;
            }
        }
    }
}

.pdf_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    /* Put content off-screen for PDF rendering */
    left: -9999px;
    top: -9999px;

    width: 612px; /* 8.5 inches at 72 DPI */
    height: 792px; /* 11 inches at 72 DPI */
    z-index: -1000;
    background-color: white;
    gap: 16px;

    padding: 72px;

    .pdf_text {
        font-size: 12px;
        font-weight: 400;
        color: blue;
    }

    .feedback_pdf_header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;

        .feedback_pdf_header_row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            width: 100%;

            .feedback_pdf_timestamp_text {
                font-size: 11px;
                font-weight: 400;

                padding: 0;
                margin: 0;
            }
        }

        .feedback_pdf_title {
            font-weight: 500;
            font-size: 12px;
            margin: 0;
            flex-grow: 1;
        }

        .feedback_pdf_athena_model {
            font-weight: 500;
            font-size: 12px;
            color: #0085ff;
            margin: 0;
        }
    }

    .model_response_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0;

        h1,
        h2 {
            font-size: 18px;
            font-weight: 600;
            margin: 16px 0;
        }

        h3 {
            font-size: 16px;
            font-weight: 600;
            margin: 16px 0 8px;
        }
        p {
            font-size: 10px;
            margin: 0 0 6px;
        }
        strong {
            font-weight: 500;
        }

        li {
            font-size: 10px;
            margin: 0 0 6px;
        }

        ul {
            margin: 0;
            padding-left: 24px;
        }
    }

    .essay_feedback_content {
        .essay_feedback_title {
            font-size: 18px;
            font-weight: 600;
            margin: 8px 0 4px;
        }

        .reveal_div_container {
            .icon_button {
                display: none;
            }
        }
    }

    .comments_container {
        .comments_title {
            font-size: 16px;
            font-weight: 600;
            margin: 16px 0;
        }

        .comments_body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 16px;

            width: 100%;
            margin: 8px 0;

            .comments_notice {
                font-size: 11px;
                font-weight: 400;
            }
        }

        .comment {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 12px;

            width: 100%;
            padding: 16px 12px;
            border-radius: 4px;
            border: 1px solid #d2d2d2;
            overflow: hidden;
            transition: border-color 0.2s ease;

            &:hover {
                border-color: #0085ff;
            }

            .quote {
                font-style: italic;
                font-size: 10px;
                font-weight: 400;
                margin: 0;
                width: 100%;
            }

            .feedback {
                font-size: 10px;
                font-weight: 400;
                margin: 0;
                width: 100%;
            }

            /* Hide comment action bar */
            .comment_action_bar,
            .icon_button,
            .icon {
                display: none;
            }
        }
    }
}

.admin_dashboard_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    padding: 0 40px;
    overflow: scroll;

    .admin_dashboard_header {
        display: flex;
    }

    .admin_dashboard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;

        width: 100%;
    }

    .tabs_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;

        gap: 10px;
        width: 100%;
        font-weight: 500;
        font-size: 0.875rem;

        border-bottom: 1px solid #e0e0e0;

        overflow: hidden;

        .tab {
            color: #424242;
            cursor: pointer;
            padding: 8px 10px;
            border-bottom: 3px solid transparent;
            text-wrap: nowrap;
        }

        .active_tab {
            position: relative;
            color: #0085ff;
            border-bottom-color: #0085ff;
        }
    }
}

.card {
    margin: 20px auto;
    width: 100%;
    padding: 20px;

    border-radius: 8px;
    border: 1px solid #e0e0e0;
    transition: border 0.2s ease;

    &:hover {
        border: 1px solid #0085ff;
    }

    .card_title {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 12px;
    }

    .card_body {
        font-size: 0.875rem;
        font-weight: 400;

        .license_container {
            padding: 4px 0;
        }

        .list {
            margin: 0;
        }

        .list_title {
            font-size: 1rem;
            font-weight: 500;
        }
    }

    .card_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
    }

    .table_container {
        overflow: auto;

        .table_page_button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 24px;
            height: 24px;

            font-weight: 400;
            font-size: 1rem;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            background-color: white;
            transition: background-color 0.2s ease;

            &:hover {
                border-color: #0085ff;
            }

            .table_page_button_icon {
                width: 16px;
                height: 16px;
            }
        }
    }

    .rotate_90_deg {
        transform: rotate(90deg);
    }

    .rotate_270_deg {
        transform: rotate(270deg);
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    td,
    th {
        border: 1px solid #ddd;
        padding: 8px;
        min-width: 100px;
    }

    th {
        background-color: #f2f2f2;
        text-align: left;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    tr:hover {
        background-color: #ddd;
    }

    .two_column_list {
        column-count: 2;
    }

    .three_column_list {
        column-count: 3;
        column-gap: 20px;
    }
}

.refresh_button {
    margin-top: 20px;

    background-color: #0085ff;
    color: white;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 0.875rem;
    font-weight: 500;
    transition: background-color 200ms ease-out;

    &:hover {
        background-color: #0099ff;
    }

    &:active {
        background-color: #0085ff;
    }
}

.flags_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .flags_list {
        margin: 0;
        padding: 0;
        width: 100%;

        .flags_list_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 24px;

            width: 100%;
            padding: 16px 0;
            list-style: none;
            border-bottom: 1px solid #e0e0e0;

            &:last-child {
                border-bottom: none;
            }
        }

        .flag_info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 6px;
        }

        .flag_user_override {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 6px;
            margin: 4px 0;

            .flag_user_override_delete_button {
                border-radius: 4px;
                padding: 4px 8px;
                transition: color 0.2s ease;

                &:hover {
                    color: #ff3d00;
                }
            }
        }

        .flag_title {
            font-size: 0.875rem;
            font-weight: 500;
            margin: 0;
        }

        .flag_description {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
        }

        .flag_toggle_button {
            padding: 8px 12px;

            border-radius: 4px;
            font-size: 0.875rem;
            font-weight: 500;
            background-color: #0085ff;
            color: white;

            transition: background-color 200ms ease-out;

            &:hover {
                background-color: #0099ff;
            }
        }

        .disable_button {
            background-color: #ff3d00;

            &:hover {
                background-color: #ff4f00;
            }
        }

        .switch_root {
            position: relative;
            width: 45px;
            height: 24px;

            background-color: #e0e0e0;
            border-radius: 9999px;
            border: none;
            outline: none;

            &[data-state="checked"] {
                background-color: #0085ff;
            }
        }

        .switch_thumb {
            display: block;
            width: 20px;
            height: 20px;
            background-color: white;
            border-radius: 9999px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
            transition: transform 100ms;
            transform: translateX(-3px);
            will-change: transform;

            &[data-state="checked"] {
                transform: translateX(16px);
            }
        }
    }
}

.debug_output_container {
    width: 100%;

    .debug_output_title {
        font-size: 0.875rem;
        font-weight: 500;
    }

    .debug_output_section_title {
        font-size: 0.875rem;
        font-weight: 500;
        margin: 0;
    }

    .model_response_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0;

        h1,
        h2 {
            font-size: 1.5rem;
            font-weight: 600;
            margin: 16px 0;
        }

        h3 {
            font-size: 1.25rem;
            font-weight: 600;
            margin: 16px 0 8px;
        }
        p {
            font-size: 0.875rem;
            margin: 0 0 8px;
            font-weight: 400; /* This diverges from other ReactMarkdown sections*/
        }
        strong {
            font-weight: 600;
        }

        li {
            font-size: 0.875rem;
            margin: 0 0 8px;
        }

        ul {
            margin: 0;
        }
    }
}

.page_container {
    overflow: scroll auto;
    width: 100%;
    flex-grow: 1;
    padding-bottom: 20vh;
}

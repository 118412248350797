.button {
    display: inline-block;
    padding: 10px 30px;
    text-align: center;
    margin: 4px 2px;

    background-color: black;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;

    transition: background-color 0.3s ease-out;
}

.button:hover {
    background-color: #333;
}

.email_verification_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    height: 100vh;
    width: 100%;

    .email_verification_title {
        font-size: 2rem;
        font-weight: bold;
        margin: 0.5rem 0;
    }

    .email_verification_description {
        font-size: 1rem;
        text-align: center;
        margin: 0;
    }
}

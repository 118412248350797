/*
 * Athena V2 CSS
 */

.application_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    flex-grow: 1;

    height: 100%;
    margin-top: -50px; /* Temporarily account for App__main_section_container top padding */
    overflow-x: hidden;
}

.application_editor_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;

    width: 100%;
    height: 100%;
}

.application {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;

    width: 100%;
    height: 100%;
    padding: 50px 10px 0 10px;
    overflow-x: hidden;
    overflow-y: auto;

    /* background-color: #f8fcff; */
}

.unreleased_prompts_container,
.application_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    max-width: 716px; /* +16px adjustment for scrollbar ._. */
    width: 100%;
    margin: 0 10px;
    padding: 0 10px 20px;

    border-bottom: 1px solid #0085ff40;
    animation: fade 0.2s forwards;
    z-index: 1;

    .dashboard_return {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        font-weight: normal;
        font-size: 0.875rem;
        color: #424242;

        .dashboard_return_icon {
            width: 24px;
            height: 24px;
            margin-top: 2px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .application_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

        width: 100%;
        margin: 10px 0;

        .application_header_logo {
            max-width: 80px;
            max-height: 80px;
        }
    }

    .application_banner_text {
        font-style: italic;
        color: rgb(83, 83, 83);
        font-weight: 300;
        font-size: 0.875rem;
    }

    .application_header_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        height: 100%;

        .application_header_title {
            font-size: 1.75rem;
            font-weight: 600;
        }
    }
    .options_bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;

        width: 100%;

        .application_dropdown_container {
            width: clamp(300px, 480px, calc(100% - 80px));
        }

        .application_saving_text {
            font-size: 0.875rem;
            color: #424242;
            margin-left: auto;
            padding-left: 10px;
            margin-right: 10px;
        }
    }

    .prompt {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;

        animation: partialFade 0.2s forwards;

        .prompt_title {
            font-size: 1.125rem;
            font-weight: 600;
        }

        p {
            font-size: 0.875rem;
            margin: 0;
        }

        ul {
            font-size: 0.875rem;
            margin: 0;
        }

        > :not(:last-child) {
            margin-bottom: 8px;
        }
    }
}

.unreleased_prompts_notice {
    font-weight: 500;
    font-size: 1rem;
    margin-left: 10px;
}

@media screen and (min-width: 1640px) {
    .application_details {
        max-width: 816px; /* +16px adjustment for scrollbar ._. */
    }
}

.dropdown_container {
    position: relative;

    width: 100%;
    min-height: 44px;

    border: 1px solid #0085ff40;
    border-radius: 4px;

    font-weight: normal;
    font-size: 0.875rem;

    .dropdown_preview {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 8px 12px;
        width: 100%;
        min-height: 44px;
        height: 100%;
        border-radius: 4px;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        transition: background-color 0.2s ease-out;

        &:hover {
            background-color: #f0f8ff;
            cursor: pointer;
        }

        .dropdown_arrow {
            width: 18px;
            height: 18px;
            transition: transform 0.2s ease-out;
        }

        .rotated_dropdown_arrow {
            transform: rotate(180deg);
        }
    }

    .dropdown_content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2px;
        z-index: 1;

        top: 44px;
        left: 0;
        width: 100%;
        max-height: 300px;
        padding: 4px 0px;
        overflow: auto;

        border: 1px solid #0085ff40;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 4px 4px;

        background-color: white;
    }

    .dropdown_item_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        gap: 10px;

        width: 100%;
        /* height: 32px; */
        padding: 10px 12px;

        transition: background-color 0.2s ease-out;

        &:hover {
            background-color: #f0f8ff;
            cursor: pointer;
        }

        .dropdown_item_text {
            flex-grow: 1;
            display: -webkit-box;
            line-clamp: 3;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            > strong {
                font-weight: 600;
            }
        }

        .dropdown_item_tag {
            font-size: 0.75rem;
            color: #424242;
            background-color: #fae5b9;

            padding: 2px 4px;
            border-radius: 4px;
        }
    }
}

.gray_background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: 69;
    background-color: rgba(0, 0, 0, 0.5);
}

/* 
 * 1500px seems to be where the right sidebar starts getting cut off
 * We need to change this value after we figure out final toolbar width
 * Keep in sync with ApplicationSidebar.module.css
 */
@media screen and (max-width: 1500px) {
    .gray_background {
        display: block;
    }
}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 4px;

    transition: background-color 0.1s ease-out;
    animation: fadeIn 1s forwards;

    &:hover {
        background-color: #d6eaff;
        cursor: pointer;
    }

    .button_icon {
        width: 24px;
        height: 24px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes partialFade {
    0% {
        opacity: 0.25;
    }
    100% {
        opacity: 1;
    }
}

.open_button {
    position: absolute;
    top: 8px;
    right: 16px;
}

.main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 100px;
    gap: 50px;
}

.page_title {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
}

.essay_selection_container {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .essay_selection_item_container {
        padding: 20px;
        border: 1px solid black;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .essay_selection_item_container_selected {
        padding: 20px;
        border: 5px solid black;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}

.section_container {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.essay_container {
    width: 500px;
    border: 1px solid black;
    border-radius: 10px;
    padding: 50px;
    max-height: 550px;
    overflow: scroll;
}

.feedback_container {
    width: 500px;
    border: 2px solid black;
    border-radius: 10px;
    padding: 50px;
    max-height: 550px;
    overflow: scroll;
}

.user_settings_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    width: clamp(300px, 100%, 900px);
    margin: 0 auto;
    padding: 0 20px 40px;

    .dashboard_title {
        font-size: 2rem;
        margin: 40px 0;
    }

    .user_settings_header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        margin: 20px 0;

        .title {
            font-size: 1.75rem;
            font-weight: 600;
            margin: 0;
        }
    }

    .user_settings {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;

        width: 100%;
    }

    .banner {
        display: block;
        margin: 40px 0 0 0;
        font-size: 0.875rem;
        font-weight: 400;
        color: #333;
        text-align: center;

        .banner_mail_link {
            color: inherit;
            text-decoration: none;
            font-weight: 500;
            transition: text-decoration 0.2s ease, color 0.2s ease;

            &:hover {
                text-decoration: underline;
                color: #0085ff;
            }
        }
    }
}

.card {
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    padding: 20px;

    border-radius: 8px;
    border: 1px solid #e0e0e0;
    transition: border 0.2s ease;
    overflow: hidden;

    &:hover {
        border: 1px solid #0085ff;
    }

    .card_title {
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .card_body {
        font-size: 0.875rem;
        font-weight: 400;

        .list_title {
            font-size: 1rem;
            font-weight: 500;
        }
    }

    .card_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 16px 0;

        gap: 12px;
    }

    .card_description {
        font-size: 0.875rem;
        font-weight: 400;
        margin: 0 0 4px;

        .link {
            font-weight: 600;
            text-decoration: none;
            color: black;
            transition: color 0.2s ease;

            &:hover {
                color: #0085ff;
            }
        }
    }

    .card_loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;

        .loading_spinner {
            width: 40px;
            height: 40px;
            margin: 20px 0;
        }
    }

    .card_row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;

        > p {
            margin: 8px 0;
        }

        .row_title {
            font-weight: 500;
            font-size: 0.875rem;
        }

        .row_content {
            font-weight: 400;
            font-size: 0.875rem;
        }

        .row_content_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            margin: auto 0;
        }

        .gradient_text {
            font-weight: 600;
            background: linear-gradient(90deg, #ff7971 0%, #0085ff 75%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
        }

        .progress_bar_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 12px;

            margin: 8px 0;

            .progress_bar_row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                flex-wrap: wrap;

                padding: 0 4px;

                .referral_success_text {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 4px;

                    font-size: 0.875rem;
                    font-weight: 500;
                }
            }

            .progress_bar {
                width: 160px;
                flex-shrink: 0;
            }
        }
    }

    .table_container {
        overflow: auto;

        .table_page_button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 24px;
            height: 24px;

            font-weight: 400;
            font-size: 1rem;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            background-color: white;
            transition: background-color 0.2s ease;

            &:hover {
                border-color: #0085ff;
            }

            .table_page_button_icon {
                width: 16px;
                height: 16px;
            }
        }
    }

    .rotate_90_deg {
        transform: rotate(90deg);
    }

    .rotate_270_deg {
        transform: rotate(270deg);
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    td,
    th {
        border: 1px solid #ddd;
        padding: 8px;
        min-width: 100px;
    }

    th {
        background-color: #f2f2f2;
        text-align: left;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    tr:hover {
        background-color: #ddd;
    }

    .two_column_list {
        column-count: 2;
    }

    .three_column_list {
        column-count: 3;
        column-gap: 20px;
    }
}

.modal_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 40px;
    width: clamp(300px, 80%, 600px);

    background-color: white;
    border-radius: 8px;
    box-shadow: 0 15px 40px 0 rgba(154, 160, 185, 0.2), 0 5px 10px 0 rgba(154, 160, 185, 0.05);
    text-align: center;
    overflow: hidden;

    .modal_title {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 16px 0 8px;
    }

    .modal_text {
        font-size: 0.875rem;
        font-weight: 400;
        margin: 8px 0;
    }

    .modal_notice {
        font-size: 0.875rem;
        font-weight: 600;
        margin: 8px 0;
        color: #0085ff;
    }

    .modal_warning_icon {
        width: 64px;
        height: 64px;
    }

    .modal_mail_link {
        color: inherit;
        text-decoration: none;
        font-weight: 500;
        transition: text-decoration 0.2s ease, color 0.2s ease;

        &:hover {
            text-decoration: underline;
            color: #0085ff;
        }
    }
}

.modal_button_options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;

    margin-top: 8px;
}

.deactivate_account_button {
    padding: 8px 12px;

    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: #ff6961;
    color: white;

    transition: background-color 200ms ease-out;

    &:hover {
        background-color: rgb(255, 105, 97, 0.9);
    }
}

.settings_action_button {
    padding: 8px 12px;
    min-width: 70px;
    min-height: 34px;

    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: #0085ff;
    color: white;

    transition: background-color 200ms ease-out;

    &:hover {
        background-color: #0099ff;
    }
}

.disabled_button {
    background-color: #e0e0e0;
    transition: background-color 200ms ease-out;
    cursor: not-allowed;

    &:hover {
        background-color: #e0e0e0;
        cursor: not-allowed;
    }
}

.check_icon {
    width: 40px;
    height: 18px;
}

.modal_button {
    padding: 10px 24px;
    margin: 8px 0;

    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: #0085ff;
    color: white;

    transition: background-color 200ms ease-out;

    &:hover {
        background-color: #0099ff;
    }
}

.modal_deactivate_button {
    background-color: #ff6961;
    color: white;

    &:hover {
        background-color: rgb(255, 105, 97, 0.9);
    }
}

.modal_cancel_button {
    background-color: #ededed;
    color: black;

    &:hover {
        background-color: #dddddd;
    }
}

.empty_page {
    display: block;
    margin: 40px 0 0 0;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;

    .mail_link {
        color: inherit;
        text-decoration: none;
        font-weight: 500;
        transition: text-decoration 0.2s ease, color 0.2s ease;

        &:hover {
            text-decoration: underline;
            color: #0085ff;
        }
    }
}

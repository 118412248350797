.certificate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    width: 100%;
    width: 11in;
    height: 8.5in;
    padding: 1in;
    overflow: hidden;

    text-align: center;
    background-image: url("../../imgs/certificate.png");
    background-size: cover;
    z-index: 1;
    background-color: white;

    .title {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .date {
        position: absolute;
        right: 205px;
        bottom: 160px;
        font-size: 1rem;
    }

    .certificate_id {
        position: absolute;
        font-size: 0.75rem;
        font-weight: 400;
        bottom: 26px;
        left: 640px;
    }

    .name {
        font-size: 2rem;
        font-weight: 600;
    }

    .course {
        font-size: 1.75rem;
        font-style: italic;
        margin-bottom: 30px;
    }

    .signature {
        margin-top: 50px;
        font-size: 1.5rem;
    }

    .logo {
        width: 100px;
        height: 100px;
    }
}
